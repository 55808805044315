.Details__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Details__close {
  width: 20px;
  height: 15px;
  cursor: pointer;
}
.Details__slider {
  width: 100%;
  height: 175px;
}
.Details_desc {
  padding-left: 15px;
  padding-right: 15px;

}
.Details__PrecedeBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkBoxWrapper {
  display: flex;
  padding-top: 15px;
  align-items: center;
  border-bottom: 1px solid rgb(181, 181, 183);
  padding-bottom: 20px;
  width: auto;
  padding-top: 15px;
  /* margin-left: 3.5rem;
  margin-right: 3.5rem; */
  flex-direction: column;
}
.checkBox {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: 3px solid var(--color-primary);
  cursor: pointer;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
/* .checkBoxWrapper > p {
  padding-left: 11px;
} */

@media screen and (min-width: 700px) and (max-width: 1324px) {
  .activityPreviewMobile__slider {
    height: 350px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .Details__title {
    padding-left: 15px;
    padding-right: 15px;
  }
}

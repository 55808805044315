.restaurantCard,
.focusedRestaurant {
  display: flex;
  margin-top: -1px;
  position: relative;
  cursor: pointer;
}
.RestaurantCardDesktop__vertical {
  width: 100%;
  max-width: 564px;
}
.restaurantImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1.5rem 2.5rem;
  text-align: center;
  cursor: pointer;
}
.RestaurantCardDesktop__horisontal > .restaurantImage {
  width: 282px;
  height: 380px;
}
.RestaurantCardDesktop__vertical > .restaurantImage {
  width: 100%;
  max-width: 564px;
  height: 118px;
}

.restaurantImageGray {
  position: relative;
  padding-top: 60px;
  opacity: 0.4;
  pointer-events: none;
}

.restaurantOpened {
  display: none;
  height: 380px;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0.5rem 0.8rem;
  background-color: white;
}
.restaurantOpened {
  padding-bottom: 2.2rem;
}
.restaurantTitle {
  font-size: 26px;
  color: white;
  font-weight: bold;
}
.title {
  font-size: 26px;
  color: var(--color-primary);
  width: 100%;
  padding-right: 30px;
  font-weight: bold;
  display: flex;
}
.menuLink {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 20px;
}
.RestaurantCardDesktop__linkIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.restaurantDetailsWrapper {
  height: 33%;
}
.detailsList {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detailsList > * + * {
  padding-top: 5px;
}
.detailsList li {
  display: flex;
}
.detailsIcon {
  width: 17x;
  height: 17px;
}
.detailsTxt {
  padding-left: 0.4rem;
}
.restaurantSliderWrapper {
  display: none;
  width: 280px;
  height: 380px;
  position: relative;
}
.focusedRestaurant {
  width: fit-content;
}
.focusedRestaurant .restaurantImage {
  display: none;
}
.focusedRestaurant .restaurantOpened {
  display: flex;
}
.focusedRestaurant .restaurantSliderWrapper {
  display: block;
  position: relative;
}
.focusedRestaurant::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 101%;
  opacity: 0.4;
  z-index: 103;
  cursor: pointer;
  left: -1px;
}
.disabled {
  pointer-events: none;
}
.disabled::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ccc;
  opacity: 0.5;
  cursor: pointer;
}
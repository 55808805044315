.popUpWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grayOutScreen {
  background-color: #ccc;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.InfoBox,
.alertBox {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  position: fixed;
  z-index: 100;
  width: 600px;
  max-width: 95%;
  height: auto;
  min-height: 250px;
  border: 2px solid var(--color-primary);
  padding: 20px 15px 15px 15px;
}
.InfoBox p,
.alertBox p {
  text-align: center;
  padding-bottom: 1.5%;
}
.InfoBox > div {
  padding: 10px;
}
.alertBox > .alertBoxButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
}
.InfoBox > div > * + *,
.alertBox > div > * + * {
  margin-left: 15px;
}
.wrongCRSAlert p {
  margin: 0;
  text-align: left;
}
.wrongCRSAlert table {
  margin: 0;
  text-align: left;
  width: 100%;
}
.wrongCRSAlert table, .wrongCRSAlert th, .wrongCRSAlert td {
  border: 1px solid #d4d4d4;
  border-collapse: collapse;
  padding: 10px;
}
.wrongCRSAlert td:nth-child(2) {
  text-align: center;
}
.wrongCRSAlert tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.infoDetailsBox {
  margin-bottom: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f2f2f4;
  position: fixed;
  z-index: 100;
  width: 80%;
  max-width: 80%;
  height: auto;
  min-height: 62%;
  border: 2px solid var(--color-primary);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
}
.infoDetailsBox > .closeButtons {
  width: 3%;
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 30px;
  right: 30px;
}
.InfoBox > * + *,
.alertBox > * + * {
  margin-top: 15px;
}
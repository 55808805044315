.MainTitle {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--color-primary);
}
.MainTitle > span {
  color: #848486;
  margin: 0 7px;
}
.logo {
  width: auto;
  cursor: pointer;
}
@media screen and (min-width: 280px) and (max-width: 1324px) {
  .MainTitle {
    font-size: 0.8rem;
    /* height: 80px !important; */
    padding: 0;
  }
}

.entertainmentWrapper {
  width: 67.70833333333334%;
  height: auto;
  flex: 1;
}
.entertainmentHeadline {
  width: 100%;
}
.entertainmentTitle {
  width: 100%;
  text-align: center;
}
.bookingView__info_mobile_mode {
  display: none;
}


@media screen and (min-width: 1325px) and (max-width: 1599px) {
  .entertainmentWrapper {
    width: 95%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .entertainmentWrapper {
    width: 80%;
  }
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .bookingView__info_desktop_mode {
    display: none;
  }
  .entertainmentWrapper {
    width: 100%;
  }
  .entertainmentTitle > p {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .bookingView__info_mobile_mode {
    display: block;
  }
  .entertainmentHeadline {
    padding-left: 10px;
    padding-right: 10px;
  }
  .entertainmentTitle {
    text-align: left;
  }
}

@media screen and (min-width: 1325px) and (max-width: 1599px) {
  .entertainmentWrapper {
    width: 95%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .entertainmentWrapper {
    width: 80%;
  }
}

@media screen and (min-width: 700px) and (max-width: 1324px) {
  .entertainmentWrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .entertainmentTitle {
    text-align: center;
  }
}

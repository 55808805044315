.activityPreviewWrapper {
  padding-top: 20px;
  width: 78.125%;
}
.activityDetails {
  width: 100%;
  background-color: var(--color-secondary);
  padding-bottom: 3rem;
}
.activityHeader {
  width: 100%;
  text-align: center;
  display: flex;
  position: relative;
}
.activityTitle {
  width: 100%;
  padding-top: 1rem;
}
.closeBTn {
  width: 60px;
  height: 45px;
  position: absolute;
  cursor: pointer;
  right: 7px;
  top: 10px;
}
.activityOverview {
  width: 100%;
  display: flex;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 1rem;
}
.activityImages {
  width: 50%;
}
.activityDescription {
  width: 50%;
  min-height: 540px;
  padding-left: 16px;
}
.sliderWrapper {
  width: 660px;
  height: 540px;
  background-size: cover;
}
.activityDescription > p:first-child {
  color: var(--color-primary);
}
.activitiesTextWrapper {
  width: 100%;
  padding-top: 2.5rem;
  white-space: pre-line;
}
.activitiesTextWrapper > * {
  line-height: 30px;
}
.activitiesTextWrapper > * > * + * {
  margin-top: 1.5rem;
}
.activitiesIconWrapper {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.activitiesIconWrapper > * {
  margin-left: 2.5rem;
}
.activityBookingSection {
  padding-top: 2.7rem;
}
.activityBooking {
  width: 100%;
  background-color: var(--color-secondary);
  padding-left: 6rem;
  padding-right: 6rem;
  padding-top:20px;
  padding-bottom: 2.5rem;
}
.date {
  width: 100%;
  border-bottom: 1px solid #b5b5b7;
  text-align: center;
  padding-bottom: 0.3rem;
  color: var(--color-primary);
}
.timeSlotsWrapper {
  width: 100%;
  display: flex;
}
.activityBookingFooter {
  width: 100%;
  min-height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.bookingButtons > button:last-child {
  margin-left: 25px;
}
.activityPreview__informationWrapper {
  margin-top: 1.5em;
  width: 100%;
}
.activityPreview__informationWrapper p {
  margin-left: 2em;
}
.activitySelectDropDown {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.activitySelectDropDownWrapper {
  width: 350px;
  padding-left: 15px;
}
.spaTermsWrapper {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.termsAndConditions {
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--color-info);
}
@media screen and (min-width: 1000px) and (max-width: 1599px) {
  .activityPreviewWrapper {
    width: 95%;
  }
  .sliderWrapper {
    width: 500px;
    height: 440px;
  }
  .activityDescription {
    padding-top: 0rem;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .activityPreviewWrapper {
    width: 90%;
  }
}

.GroupBanner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgb(204, 229, 255);
  color: #000;
  padding: 10px 30px 10px 30px;
  margin-bottom: 15px;
}
.GroupBanner__icon {
  margin-right: 20px;
  width: 25px;
  height: 25px;
}
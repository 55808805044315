.day_use_confirmation_screen_wrapper {
    width: 95%;
    left: 0;
    right: 0;
    margin: 20px auto auto auto;
    padding: 0 20px 20px;
}

.day_use_confirmation_screen_card_wrapper {
    background: #F2F4F5;
    margin: 20px auto auto auto;
    padding: 15px;
}

.day_use_confirmation_screen_row {
    display: flex;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.day_use_confirmation_screen_cell {
    width: 150px;
}

@media screen and (min-width: 1024px) {
    .day_use_confirmation_screen_cell {
        width: 150px;
    }
    .day_use_confirmation_screen_wrapper {
        width: 100%;
        margin: 0;
    }
    
}
.bookingWrapper {
  width: 67.70833333333334%;
  height: auto;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bookingHeadline {
  width: 100%;
}
.bookingTitle {
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 1325px) and (max-width: 1620px) {
  .bookingWrapper {
    width: 95%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1905px) {
  .bookingWrapper {
    width: 80%;
  }
}
@media screen and (min-width: 280px) and (max-width: 1324px) {
  .bookingWrapper {
    width: 100%;
  }
  .bookingTitle > p {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .bookingHeadline {
    padding-bottom: 20px;
  }
  .bookingTitle {
    text-align: left;
  }
}

@media screen and (min-width: 1325px) and (max-width: 1599px) {
  .bookingWrapper {
    width: 95%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .bookingWrapper {
    width: 80%;
  }
}

@media screen and (min-width: 700px) and (max-width: 1324px) {
  .bookingWrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
  .bookingTitle {
    text-align: center;
  }
}

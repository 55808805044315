.popUpWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 2223;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grayOutScreen {
  background-color: #ccc;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.InfoBox,
.alertBox {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f2f2f4;
  position: fixed;
  z-index: 100;
  width: 95%;
  height: auto;
  min-height: 250px;
  border: 2px solid var(--color-primary);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
}
.InfoBox p,
.alertBox p {
  text-align: center;
  padding-bottom: 1.5%;
}
.InfoBox > div {
  padding: 10px;
}
.alertBox > .alertBoxButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  padding-bottom: 20px;
  gap: 30px;
}
.infoDetailsBox {
  margin-bottom: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f2f2f4;
  position: fixed;
  z-index: 100;
  width: 80%;
  max-width: 80%;
  height: auto;
  min-height: 62%;
  border: 2px solid var(--color-primary);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
}
.infoDetailsBox > .closeButtons {
  width: 3%;
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 30px;
  right: 30px;
}
.bookingDetailsPopUp {
  width: 84%;
  max-height: 95%;
  padding: 30px 25px;
  background-color: #f2f2f4;
  position: relative;
  display: flex;
  flex-direction: column;
}
.successInfo {
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  padding-bottom: 20px;
}

.bookingDetailsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bookingList {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bookingList > * + * {
  margin-top: 15px;
}
.previewButtons {
  width: 100%;
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.itineraryInfoText {
  color: #2F5C27;
  font-size: 14px;
  max-width: 550px;
  margin-right: 45px;
  text-align: center;
}
.bookingPreviewItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.singleBooking {
  list-style-type: none;
  width: 550px;
  min-height: 45px;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 5px;
}
.singleBooking * {
  font-size: 16px;
  color: #fff;
}
.singleBooking *:first-child {
  width: 25%;
}
.singleBooking *:nth-child(2) {
  color: #fff;
  width: 20%;
}
.singleBooking *:last-child {
  width: 50%;
}
.progressIndicator {
  margin-left: 20px;
  width: 25px;
}
.loader {
  width: 25px;
  height: 25px;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left: 20px;
}
.cancellingMark {
  text-decoration-line: line-through;
  text-decoration-color: white;
}
.cancelLine {
  width: 100%;
  height: 15px;
  background-color: var(--color-error);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 280px) and (max-width: 745px) {
  .singleBooking {
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 15px;
    min-height: 45px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .singleBooking *:first-child {
    width: auto;
  }
  .singleBooking *:nth-child(2) {
    width: auto;
    padding-left: 10px;
  }
  .singleBooking * {
    width: auto;
    /* padding-bottom: 8px; */
  }
  .successInfo {
    width: 100%;
  }
}
@media screen and (min-height: 320px) and (max-height: 430px) {
  .successInfo {
    width: 100%;
  }
  .singleBooking * {
    width: 50%;
  }
}
@media screen and (min-width: 751px) and (max-width: 1324px) {
  .successInfo {
    width: 100%;
  }
  .singleBooking * {
    width: 50%;
  }
}
@media screen and (min-width: 228px) and (max-width: 501px) {
  .singleBooking {
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 280px) and (max-width: 745px) {
  .bookingDetailsPopUp {
    width: 95%;
    height: auto;
    padding: 1rem;
  }
  .bookingDetailsWrapper {
    justify-content: center;
    max-height: 450px;
    overflow-y: scroll;
  }
  .bookingDetailsWrapper > * {
    text-align: center;
  }
  .successInfo {
    height: auto;
    padding-bottom: 20px;
    text-align: center;
  }
  .bookingDetailsWrapper > div:last-child {
    margin-top: 50px;
  }
  .previewButtons {
    flex-direction: column-reverse;
  }
  .previewButtons > button:first-child {
    margin-top: 20px;
  }
  .bookingList {
    width: 100%;
  }
}
@media screen and (min-height: 320px) and (max-height: 430px) {
  .bookingDetailsPopUp {
    width: 95%;
    padding: 1rem;
  }
  .bookingDetailsWrapper {
    justify-content: center;
    max-height: 150px;
    overflow-y: scroll;
  }
  .bookingDetailsWrapper > * {
    text-align: center;
  }
  .successInfo {
    height: auto;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 745px) and (max-width: 1324px) {
  .bookingDetailsPopUp {
    width: 95%;
    padding: 1rem;
  }
  .bookingDetailsWrapper {
    justify-content: center;
    max-height: 150px;
    overflow-y: scroll;
  }
  .bookingDetailsWrapper > * {
    text-align: center;
  }
  .successInfo {
    height: auto;
    padding-bottom: 20px;
  }
  .previewButtons {
    justify-content: space-around;
  }
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .InfoBox,
  .alertBox {
    width: 95%;
    max-width: 600px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .InfoBox p,
  .alertBox p {
    font-size: 18px;
  }
  .InfoBox > * + *,
  .alertBox > * + * {
    margin-top: 25px;
  }
}

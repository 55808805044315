.day_use_register_view_wrapper {
    background: #F2F4F5;
    width: 100%;
    left: 0;
    right: 0;
    padding: 20px;
}

.day_use_reg_view_checkbox_wrapper {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.day_use_captcha_wrapper {
    padding-top: 20px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.day_use_reg_view_captcha_icon {
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.day_use_reg_view_error_wrapper {
    background: #9F3737;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.day_use_reg_view_btns_wrapper {
    display: flex;
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.day_use_reg_view_captcha_input {
    height: 50px;
    width: 150px;
    padding: 15px;
    background: #F2F4F5;
}

.day_use_captcha_text {
    width: fit-content;
    background: white;
}

.day_use_captcha_icon {
    width: "30px";
    height: "30px";
}
.WhatsOnGuide {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.WhatsOnGuide__dynamicContent {
  width: 90%;
}
.WhatsOnGuide__articles {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  overflow: hidden;
}
/* @media screen and (min-width: 1000px) and (max-width: 2000px) { */
  .WhatsOnGuide__Filter_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
/* } */
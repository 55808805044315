.helpItem {
    width: 100%;
    background-color: #fff;
    padding-top: 15px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 50px;
    height: auto;
    overflow: hidden;
    position: relative;
}
.helpItem_purpleTitle > p:first-child {
    color: var(--color-primary);
}
.helpItem_whiteTitle > * {
    color: #fff;
}
.helpItem_whiteTitle {
    max-height: fit-content;
    padding-bottom: 15px;
}
.helpItem_whiteTitle > button {
    margin-top: 25px;
}
.helpItem__icon {
    width: 27px;
    height: 27px;
    top: 5px;
    right: 15px;
    position: absolute;
    cursor: pointer;
}

.helpItem > div {
    max-height: 0;
    transition: all 0.6s;
}
.helpItem_whiteTitle > div {
    max-height: 100%;
    transition: 0;
}

.helpItem_expand > div {
    max-height: 1000px;
    padding-bottom: 20px;
}
.helpItem_expand .helpItem__icon {
    transform: rotate(180deg);
}
.RestaurantMobileNew {
  width: 100%;
  position: relative;
}
.RestaurantMobileNew__preview {
  width: 100%;
  height: 118px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.RestaurantMobileNew__focused > .RestaurantMobileNew__preview,
.RestaurantMobileNew__details {
  display: none;
}

.RestaurantMobileNew__focused > .RestaurantMobileNew__details {
  display: block;
  width: 100.3%;
}
.RestaurantMobileNew__carousel {
  height: 348px;
  position: relative;
}
.RestaurantMobileNew__information {
  padding: 20px 10px;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.RestaurantMobileNew__menu {
  color: var(--color-primary);
  text-decoration: underline;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 105;
  font-size: 20px;
}
.RestaurantCardDesktop__linkIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  z-index: inherit;
}
.RestaurantMobileNew__categories {
    padding-top: 10px;
    padding-bottom: 20px;
}
.RestaurantMobileNew__categorie {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.RestaurantMobileNew__categorie_icon {
  width: 15x;
  height: 15px;
}
.RestaurantMobileNew__categorie_txt {
  margin-left: 10px;
}
.focusedRestaurant::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 101%;
  background-color: rgba(84, 95, 141);
  opacity: 0.4;
  z-index: 103;
  bottom: 0;
  cursor: pointer;
}
.disabled::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ccc;
  opacity: 0.5;
  cursor: pointer;
  bottom: 0;
}
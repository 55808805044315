.inputCardWrapper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
}
.inputLabel {
  font-size: 20px;
  padding-bottom: 10px;
  color: #373737;
  display: flex;
}
.input {
  width: 100%;
  height: 50px;
  background-color: white;
  color: #000;
  padding-left: 10px;
  font-size: 16px;
}
.input::placeholder {
  color: #afafaf;
  font-size: 16px;
}
@media screen and (min-width: 280px) and (max-width: 380px) {
  .input::placeholder {
    font-size: 14px;
  }
}

@media screen and (min-width: 280px) and (max-width: 900px) {
  .inputLabel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.CheckBox {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-primary);
    cursor: pointer;
}
.CheckBox > * {
    width: 70% !important;
    height: 70% !important;
    stroke: var(--color-primary) !important;
}
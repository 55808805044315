.DropDownBox__list {
  width: calc(100% + 6px);
  height: auto;
  background-color: #f6f6f8;
  position: absolute;
  left: -3px;
  top: calc(100% + 3px);
  z-index: 100;
  box-shadow: 0 4px 9px -2px #707070;
  padding-left: 10px;
  padding-right: 10px;
}
.DropDownBox__list li {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
}
.DropDownBox__list li:hover {
  font-weight: bold;
  text-decoration: underline;
}

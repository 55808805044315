.bookingPreviewItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.singleBooking {
  list-style-type: none;
  width: 510px;
  min-height: 55px;
  /* background-color: #42485d; */
  align-items: center;
  padding-left: 15px;
  padding-right: 5px;
}
.singleBooking * {
  font-size: 16px;
  color: #fff;
}
.singleBooking *:first-child {
  width: 25%;
}
.singleBooking *:nth-child(2) {
  color: #fff;
  width: 20%;
}
.singleBooking *:last-child {
  width: 50%;
}
.progressIndicator {
  margin-left: 20px;
  width: 25px;
}
.loader {
  width: 25px;
  height: 25px;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left: 20px;
}
.cancellingMark {
  text-decoration-line: line-through;
  text-decoration-color: white;
}
.cancelLine {
  width: 100%;
  height: 15px;
  background-color: var(--color-error);
}

.cancellingMark {
  text-decoration-line: line-through;
  text-decoration-color: white;
}
.cancelLine {
  width: 100%;
  height: 15px;
  background-color: var(--color-error);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 280px) and (max-width: 745px) {
  .singleBooking {
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 15px;
    min-height: 45px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .singleBooking *:first-child {
    width: auto;
  }
  .singleBooking *:nth-child(2) {
    width: auto;
    padding-left: 10px;
  }
  .singleBooking * {
    width: auto;
    /* padding-bottom: 8px; */
  }
  .successInfo {
    width: 100%;
  }
}
@media screen and (min-height: 320px) and (max-height: 430px) {
  .successInfo {
    width: 100%;
  }
  .singleBooking * {
    width: 50%;
  }
}
@media screen and (min-width: 751px) and (max-width: 1324px) {
  .successInfo {
    width: 100%;
  }
  .singleBooking * {
    width: 50%;
  }
}
@media screen and (min-width: 228px) and (max-width: 501px) {
  .singleBooking {
    flex-direction: column;
    padding-top: 5px;
  }
}

.headline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.information {
  width: 100%;
  margin-bottom: 20px;
  padding-right: 10px;
  overflow: hidden;
  overflow-y: auto;
  /* min-height: 170px; */
}
.information > * + * {
  margin-top: 1em;
}

@media screen and (min-width: 280px) and (max-width: 800px) {
  .information {
    margin-bottom: 15px;
    min-height: 90px;
  }
}

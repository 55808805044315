.GuideItemTV {
  background-color: #f2f2f4;
  border-radius: 5px;
  overflow: hidden;
  width: 900px;
  height: 1350px;
  flex-shrink: 0;
}
.GuideItemTV__slider,
.GuideItemTV__details {
  width: 100%;
  height: 50%;
}
.GuideItemTV__details {
  padding: 25px 20px 10px;
}
.GuideItemTV__details_txt {
  margin-top: 15px;
}
.GuideItemTV__details_txt > * {
  padding-bottom: 13px;
  font-size: 20px;
}
@media screen and (min-width: 1920px) and (max-width: 2000px) {
  .GuideItemTV {
    width: 500px;
    height: 800px;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2499px) {
  .GuideItemTV {
    width: 600px;
    height: 1000px;
  }
}

@media screen and (min-width: 2500px) and (max-width: 3000px) {
  .GuideItemTV {
    width: 700px;
    height: 1100px;
  }
}

.VenueBanner {
    position: absolute;
    z-index: 105;
    background: var(--color-primary);
    width: 100%;
    height: 10%;
    left: -1.2px;
    top: -1.2px;
}

.BannerText {
    text-align: center;
    height: 100%;
    display: grid;
    align-items: center;
    width: 100%;
    position: absolute;
    color: var(--color-secondary);
    z-index: 106;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 15px;
  }
  
.banner_check_icon {
    width: 1.5rem;
    margin-left: 10px;
  }

  @media (min-width: 400px) and (max-width: 520px) {
    .BannerText {
      font-size: 14px;
    }
    .banner_check_icon {
      width: 1.2rem;
    }
  }

  @media (min-width: 280px) and (max-width: 400px) {
    .BannerText {
      font-size: 12px;
    }
    .banner_check_icon {
      width: 1.2rem;
    }
  }
  
.timeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: var(--color-primary);
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  font-weight: bold;
}
.focusedTime {
  background-color: var(--color-primary);
}
.focusedTime p {
  color: var(--color-secondary);
}
.timeButton > div {
  width: 25px;
  height: 25px;
  border: 1px solid gray;
  border-radius: 35px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeButton > div > img {
  width: 15px;
  height: 15px;
}
.test {
  pointer-events: none;
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .timeButton {
    color: var(--color-primary);
    margin-bottom: 5px;
  }
  .timeButton p {
    overflow: hidden;
    height: fit-content;
    text-overflow: ellipsis;
  }
}

.typeCard {
  padding-left: 25px;
  padding-right: 12px;
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: background-color 0.4s;
}

.typeCard > p {
  color: #fff;
}

.typeCard:hover {
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: darken;
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .typeCard {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1324px) {
  .typeCard > p {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 600px) {
  .typeCard > p {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 280px) and (max-width: 400px) {
  .typeCard > p {
    font-size: 10px !important;
  }
}

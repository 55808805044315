.bookingDetailsPopUp {
  width: 1110px;
  max-height: 95%;
  padding: 30px 25px;
  background-color: #f2f2f4;
  position: relative;
  display: flex;
  flex-direction: column;
}
.successInfo {
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  padding-bottom: 20px;
}

.bookingDetailsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bookingList {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bookingList > * + * {
  margin-top: 15px;
}
.previewButtons {
  width: 100%;
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.itineraryInfoText {
  color: #2F5C27;
  font-size: 14px;
  max-width: 550px;
  margin-right: 45px;
  text-align: center;
}
@media screen and (min-width: 280px) and (max-width: 745px) {
  .bookingDetailsPopUp {
    width: 95%;
    height: auto;
    padding: 1rem;
  }
  .bookingDetailsWrapper {
    justify-content: center;
    max-height: 450px;
    overflow-y: scroll;
  }
  .bookingDetailsWrapper > * {
    text-align: center;
  }
  .successInfo {
    height: auto;
    padding-bottom: 20px;
    text-align: center;
  }
  .bookingDetailsWrapper > div:last-child {
    margin-top: 50px;
  }
  .previewButtons {
    flex-direction: column-reverse;
  }
  .previewButtons > button:first-child {
    margin-top: 20px;
  }
  .bookingList {
    width: 100%;
  }
}
@media screen and (min-height: 320px) and (max-height: 430px) {
  .bookingDetailsPopUp {
    width: 95%;
    padding: 1rem;
  }
  .bookingDetailsWrapper {
    justify-content: center;
    max-height: 150px;
    overflow-y: scroll;
  }
  .bookingDetailsWrapper > * {
    text-align: center;
  }
  .successInfo {
    height: auto;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 745px) and (max-width: 1324px) {
  .bookingDetailsPopUp {
    width: 95%;
    padding: 1rem;
  }
  .bookingDetailsWrapper {
    justify-content: center;
    max-height: 150px;
    overflow-y: scroll;
  }
  .bookingDetailsWrapper > * {
    text-align: center;
  }
  .successInfo {
    height: auto;
    padding-bottom: 20px;
  }
  .previewButtons {
    justify-content: space-around;
  }
}

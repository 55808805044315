.day_use_wrapper {
    flex: 1 1;
    height: 100vh;
    width: 100%;
    position: relative;
}

.day_use_avail_section_icon {
    width: 30px;
    height: 30px;
}
.imageContainer {
  width: 400px;
  height: 240px;
  text-align: center;
}
.imageContainer img {
  object-fit: contain
}
@media screen and (min-width: 280px) and (max-width: 750px) {
  .imageContainer {
    display: none;
  }
}
@media screen and (min-height: 320px) and (max-height: 430px) {
  .imageContainer {
    display: none;
  }
}
@media screen and (min-width: 751px) and (max-width: 1324px) {
  .imageContainer {
    display: none;
  }
}
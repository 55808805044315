.navBarNew,
.navBarReached {
  width: 100%;
  height: 75px;
  background-color: var(--color-primary);
  position: relative;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navList {
  height: 100%;
  display: flex;
  align-items: center;
}
.navBarNew__logOut {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
}
.navBarNew__logOut > p {
  color: var(--color-info);
}

.navBarReached {
  position: fixed;
  background-color: var(--color-primary);
  justify-content: flex-start;
  overflow-y: hidden;
}
.navBarReached .logoWrapper {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 10px;
}
.navBarReached .titleWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  right: 0;
  z-index: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.logOut {
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--color-info);
  cursor: pointer;
}
.navList {
  color: var(--color-primary);
  z-index: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-grow: 1;
  justify-content: center;
  /* justify-content: flex-end; */
}
.navList * {
  color: var(--color-secondary);
}
.blendShadow {
  width: 5px;
  height: 100%;
  position: absolute;
  background: var(--color-primary);
  left: -3px;
  z-index: 4;
}
.blendShadow::after {
  content: "";
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: var(--color-primary);
  z-index: 6;
  filter: blur(10px);
}
.mobileNav__opened,
.mobileNav {
  display: none;
}
.createHeight {
  display: none;
}
.NavBar_home_icon {
  position: absolute;
  left: 20px;
  cursor: pointer;
}
.mobileNav__logout_kiosk {
  display: none;
}
@media screen and (min-width: 1366px) {
  .mobileNav__logout_kiosk {
    display: block;
    position: absolute;
    right: 70px;
    color: var(--color-info);
    font-size: 1.3rem;
    font-weight: bold;
    align-self: center;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1810px) {
  .navBarReached .titleWrapper {
    width: 15%;
  }
  .navBarReached {
    font-size: 12px;
  }
}

@media screen and (min-width: 280px) and (max-width: 1870px) {
  .navBarNew,
  .navBarReached {
    display: none;
  }
  .mobileNav,
  .mobileNav__opened {
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: 0.4s height ease-in-out;
  }
  .createHeight {
    display: block;
    width: 100%;
    height: 55px;
    opacity: 0;
  }
  .mobileNav > button,
  .mobileNav__opened > button {
    width: 130px;
  }

  .mobileNav__burger_logo_wrapper {
    display: flex;
    min-height: 55px;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .mobileNav__burger {
    height: 100%;
    width: 50px;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .line {
    width: 25px;
    height: 3px;
    background-color: var(--color-secondary);
    border-radius: 5px;
  }
  .burger__line2 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mobileNav__opened {
    height: 100vh;
  }
  .mobileNav > .mobileNav__list {
    height: 0;
    pointer-events: none;
  }
  .mobileNav__list {
    opacity: 0;
    transition: all 1.1s;
  }
  .mobileNav__opened > .mobileNav__list {
    width: 100%;
    height: 100vh;
    opacity: 1;
    padding-top: 45px;
    padding-left: 60px;
    padding-bottom: 15px;
    overflow-x: scroll;
  }
  .mobileNav__opened > .mobileNav__list > * + * {
    margin-top: 30px;
  }
  .mobileNav__opened .mobileNav__burger > .burger__line2 {
    display: none;
  }
  .mobileNav__opened .mobileNav__burger > .burger__line1 {
    transform: rotateZ(-45deg);
  }
  .mobileNav__opened .mobileNav__burger > .burger__line3 {
    margin-top: -3px;
    transform: rotateZ(45deg);
  }
  .mobileLogOut {
    font-weight: bold;
    color: var(--color-info);
    width: fit-content;
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
  }
  .mobileNav .mobileLogOut {
    margin: 0;
    text-align: left;
  }
}
@media screen and (min-height: 320px) and (max-height: 430px) {
  .mobileNav__opened > .mobileNav__list {
    padding-top: 15px;
  }
}

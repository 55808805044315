.cardWrapper {
  width: 48%;
  background: #fff;
  border: 1px solid #ccc;
}

.leftSummaryContainer {
  border-right: 3px solid #ccc;
}

.leftSummaryContainer > p:first-child {
  padding-top: 0 !important;
}

.rightSummaryContainer > p:first-child {
  padding-top: 0 !important;
}

.rightSummaryContainer {
  padding-left: 20px;
  padding-right: 20px;
}

.editIcon > * {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.iconFillInPast > svg {
  fill: #949494 !important;
}

.dropArrow {
 display: flex;
}

.dropArrow > svg {
  fill: white !important;
}

.rotate180deg {
  transform: rotate(180deg);
  transition: transform 0.3s,
}

.rotate0deg {
  transform: rotate(0deg);
  transition: transform 0.3s,
}

.restaurantImage_shrink > * {
  width: 160px !important;
}
.restaurantImage_shrink > * > h1 {
  font-size: 18px !important;
}
.restaurantImage_shrink > *:last-child {
  font-size: 20px !important;
}
.RestaurantMobileNew {
  display: none;
}
.RestaurantMobileNew > *:last-child {
  font-size: 20px !important;
}
.GuideItem {
  width: 100%;
  
  background-color: white;
  display: flex;
  margin-bottom: 20px;
}
.GuideItem__imgWrapper {
  height:auto;
  width: 147px;
}
.GuideItem__img {
  width: 100%;
  height: 100%
}
.GuideItem__details {
  padding: 10px;
  position: relative;
  flex: 1;
}
.GuideItem__details_list {
  padding-top: 10px;
  font-size: 16px;
  line-height: 24px;
}
.GuideItem__dropDown_opened,
.GuideItem__dropDown_closed {
  width: 30px;
  height: 30px;
}
.GuideItem__dropDown_closed {
  position: absolute;
  right: 10px;
  top: 10px;
}
.GuideItem .expandShow {
  display: none;
}
.GuideItem_opened .GuideItem__dropDown_closed {
  display: none;
}
.GuideItem_opened .expandShow {
  display: block;
  padding-top: 10px;
}
.GuideItem_opened {
  height: auto;
  flex-direction: column;
}
.GuideItem_opened > .GuideItem__imgWrapper {
  width: 100%;
  height: 170px;
}
.GuideItem_opened .GuideItem__details_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.GuideItem__expandEnding {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GuideItem__dropDown_opened {
    transform: rotate(180deg);
}

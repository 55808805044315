.warnerStayButton {
  min-height: 64px;
  color: var(--color-secondary);
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  border: 3px solid var(--color-primary);
  outline: none;
  text-transform: uppercase;
}
.warnerStayButton:hover {
  border: 3px solid var(--color-primary);
}
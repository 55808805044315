.SelectCard {
  width: 272px;
  height: auto;
  height: fit-content;
  background-color: var(--color-secondary);
  border: var(--color-primary) 3px solid;
  padding-left: 20px;
  padding-right: 15px;
  color: var(--color-primary);
  cursor: pointer;
  font-size: 18px;
}
/* .SelectCard *, .SelectCard *::after, .SelectCard *::before {
  border: none !important;
} */
.SelectCard button > svg {
  display: none;
}
.activityTimeSlotsMobile {
  width: 100%;
  min-height: 254px;
  background-color: var(--color-secondary);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 15px;
  overflow: hidden;
}
.activityTimeSlotsMobile__date_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.activityTimeSlotsMobile__date,
.activityTimeSlotsMobile__add {
  font-size: 18px;
}
.activityTimeSlotsMobile__add {
  color: var(--color-info);
  text-decoration: underline;
  cursor: pointer;
}
.activityTimeSlotsMobile__hours {
  display: grid;
  grid-template-columns: repeat(auto-fill, 109px);
  grid-auto-rows: 49px;
  grid-row-gap: 15px;
  grid-column-gap: 5px;
  max-height: 120px;
  overflow: hidden;
  transition: max-height 1s;
}
.activityTimeSlotsMobile__expand .activityTimeSlotsMobile__hours{
  max-height: 500px;
}
.activityTimeSlotsMobile__hours > * {
  margin-bottom: 0;
}
.activityTimeSlotsMobile__error {
  width: 100%;
  padding-top: 20px;
}
.activityTimeSlotsMobile__quantity {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

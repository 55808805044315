.logoWrapper {
  display: flex;
  justify-content: center;
  background: var(--color-primary);
  height: 100px;
}
.contentWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.phoneImgWrapper {
  /* width: 100%; */
  position: relative;
}
.phoneImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: rotate(3deg);
  position: absolute;
}

.headerStyle {
  color: white;
}

.textStyle {
  color: white;
  padding-top: 25px;
}

.iconStyle {
  min-height: 40px;
}

@media screen and (min-width: 320px) and (max-width: 1052px) {
  .logo {
    width: 320px;
  }
  .contentWrapper {
    padding-top: 10px;
    /* margin: 10px auto; */
    /* padding-left: calc(100% - 1000px / 2); */

    /* left: 50%; */
    /* transform: translateX(35%); */
  }
  .phoneImgWrapper {
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    left: -67px;
    right: 0;
    width: 173px;
  }
  .phoneImg {
    height: 100%;
    width: 175px;
    /* right: 0%; */
    bottom: 4%;
    left: calc(100% - 25%);
  }
  .headerStyle {
    font-size: 20px;
    /* margin: 10px 37%; */
    margin-left: auto;
    margin-right: auto;
    left: -67px;
    right: 0;
    position: relative;
  }
  .textStyle {
    font-size: 14px;
  }
  .iconStyle {
    width: 120px;
  }
}

@media screen and (min-width: 1052px) {
  .logo {
    width: 320px;
  }
  .contentWrapper {
    padding-top: 10px;
    /* margin: 10px auto; */
    /* padding-left: calc(100% - 1000px / 2); */

    /* left: 50%; */
    /* transform: translateX(35%); */
  }
  .phoneImgWrapper {
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    left: -67px;
    right: 0;
    width: 260px;
  }
  .phoneImg {
    height: 170%;
    width: 315px;
    /* right: 0%; */
    bottom: -62%;
    left: calc(100% - 20%);
  }
  .headerStyle {
    font-size: 30px;
    /* margin: 10px 37%; */
    margin-left: auto;
    margin-right: auto;
    left: -67px;
    right: 0;
    position: relative;
  }
  .textStyle {
    font-size: 24px;
  }
  .iconStyle {
    width: 200px;
  }
}

.popover {
  background: #ccc;
  margin-left: 15px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
}

/* .popover:after {
  content: " ";
  height: 20px;
  width: 20px;
  pointer-events: none;
} */

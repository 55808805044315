.dayBtn {
  width: 200px;
  height: 55px;
  display: flex;
  margin-right: 5px;
  transition: 0.3s ease-in-out all;
  cursor: pointer;
  border: 2px solid var(--color-secondary);
  border-bottom: 1px;
}
.shrinkDays {
  width: 140px;
}
.day {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  color: var(--color-primary);
  font-weight: bold;
  padding: 5px;
}
/* .day > span {
  margin-right: 5px;
} */
.focused {
  height: 70px;
  /* border: 4px solid #f2f2f4; */
  background-color: var(--color-secondary);
  /* border-image: linear-gradient(to bottom, var(--color-primary) -20%, #f2f2f4 90%) 1 20%; */
  /* background-image: linear-gradient(#f2f2f4, #f2f2f4); */
}
.focused > .day {
  flex-direction: column;
}
#wholeStay {
  display: none;
}
/* .dayBtn .dayWrapper::after {
  content: " -";
} */
.focused .dayWrapper::after {
  content: "";
}
.DayButton__whats_on_guide_date {
  font-size: 14px;
}
.DayButton__whats_on_guide_day {
  font-weight: lighter;
  font-size: 14px;
}
@media screen and (min-width: 700px) and (max-width: 1150px) {
  .dayBtn {
    width: 120px;
  }
}

@media screen and (min-width: 280px) and (max-width: 700px) {
  .dayBtn {
    width: 110px;
    height: 50px;
  }
  .focused {
    height: 55px;
    width: 110px;
    background-color: var(--color-secondary);
  }
  .day {
    flex-direction: column;
    overflow: hidden;
  }
  .dayBtn .dayWrapper::after {
    content: "";
  }
  .timeDisplayed {
    margin-left: 0;
  }
}

.entertainmentCardMobile {
  display: flex;
  position: relative;
}

.entertaintmentImage {
  width: 50%;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1.5rem 2.5rem;
  text-align: center;
  cursor: pointer;
}

.selectionBox {
  height: 273px;
  margin-bottom: 25px;
  width: 100%;
}

.unselect {
  position: relative;
  z-index: 104;
}

.highlight:after {
  content: "";
  display: block;
  position: absolute;
  height: 101%;
  width: 101%;
  top: -1px;
  left: -1px;
  background-color: var(--color-primary);
  opacity: 0.4;
  z-index: 103;
  border-radius: 10px;
}

.disable:after {
  content: "";
  display: block;
  position: absolute;
  height: 100.1%;
  width: 100.2%;
  top: 0px;
  left: -1px;
  background-color: #ccc;
  opacity: 0.5;
  border-radius: 10px;
}

.entertainmentDetails {
  width: 50%;
  height: 260px;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.5rem;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title {
  font-size: 20px;
  color: var(--color-primary);
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 48px;
  max-height: 48px;
  font-weight: bold;
}

.time {
  color: var(--color-primary);
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
  /* margin-top: 10px; */
  height: 35px;
  max-height: 40px;
}

.time h1 {
  font-weight: bold;
}

.information_icon {
  margin-left: 10px;
  width: 1.9rem;
}

.entertainmentDetailsWrapper {
  text-align: left;
  margin-top: 10px;
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
  position: absolute;
  font-size: 16px;
}

.entertainmentImgSliderWrapper {
  width: 50%;
  height: 260px;
}

.banner {
  position: absolute;
  z-index: 105;
  background: var(--color-primary);
  width: 50%;
  height: 13%;
  left: -1.2px;
}

.bannerText {
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
  width: 100%;
  position: absolute;
  color: white;
  z-index: 106;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 15px;
}

.bannerText p {
  font-size: 16px !important;
}

.banner_check_icon {
  width: 1.5rem;
  margin-left: 10px;
}

.entertainmentDescription {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 113px;
  max-height: 113px;
  font-size: 16px !important;
}

/* @media (min-width: 550px) and (max-width: 800px) {
  .selectionBox {
    width: 50%;
    display: inline-block;
  }
} */

@media (min-width: 400px) and (max-width: 520px) {
  .title {
    font-size: 18px;
    height: 41px;
    max-height: 41px;
  }
  .time {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .entertainmentDescription {
    height: 128px;
    max-height: 128px;
    -webkit-line-clamp: 7;
  }
  .entertainmentDetails {
    padding: 0.4rem
  }
  .entertainmentDetailsWrapper {
    font-size: 14px;
  }
  .bannerText {
    font-size: 14px;
  }
  .banner_check_icon {
    width: 1.2rem;
  }
  .information_icon {
    width: 1.5rem;
  }
}

@media (min-width: 280px) and (max-width: 400px) {
  .entertainmentDetails {
    padding: 0.4rem;
  }
  .title {
    font-size: 18px;
    height: 41px;
    max-height: 41px;
  }
  .time {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .entertainmentDescription {
    height: 128px;
    max-height: 128px;
    -webkit-line-clamp: 7;
  }
  .entertainmentDetailsWrapper {
    font-size: 12px;
    margin-top: 10px;
  }
  .bannerText {
    font-size: 12px;
  }
  .banner_check_icon {
    width: 1.2rem;
  }
  .information_icon {
    width: 1.5rem;
    margin-left: 5px;
  }
}

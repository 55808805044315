.infoDetailsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: 80%;
  max-width: 80%;
  min-width: 300px;
  /* height: 50%; */
  /* max-height: 590px; */
  border: 2px solid var(--color-primary);
  border-radius: 5px;
  padding: 15px;
}

.infoDetailsBox>.closeButtons {
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.contentWrapper {
  width: 100%;
  display: flex;
}

.imgSliderWrapper {
  width: 60%;
  max-width: 665px;
}

.infoWrapper {
  width: 40%;
  padding-left: 20px;
  /* height: 475px; */
  display: flex;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 55px;
  padding-bottom: 40px; */
}

.shortDesc {
  font-weight: bold;
  padding-bottom: 30px;
}

@media screen and (min-width: 280px) and (max-width: 750px) {
  .imgSliderWrapper {
    width: 100%;
  }
  .infoDetailsBox {
    padding: 0;
    border: 8px solid #6a6a6a;
    width: 92%;
    max-width: 92%;
  }
}
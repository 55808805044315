.day_use_booking_view_wrapper {
    background: #F2F4F5;
    width: 100%;
    left: 0;
    right: 0;
    padding: 20px;
}

.day_use_icon {
    height: 30px;
    width: 30px;
}
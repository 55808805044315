.activityTimeSlotsMobile {
  width: 100%;
  background-color: #f2f2f4;
  border-radius: 5px;
  padding-right: 10px;
  padding-bottom: 15px;
  overflow: hidden;
}
.activityTimeSlotsMobile__date_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
}
.activityTimeSlotsMobile__date,
.activityTimeSlotsMobile__add {
  font-size: 18px;
}
.activityTimeSlotsMobile__add {
  color: var(--color-info);
  text-decoration: underline;
}
.activityTimeSlotsMobile__hours {
  /* display: grid;
    grid-template-columns: repeat(auto-fill, 109px);
    grid-auto-rows: 49px;
    grid-row-gap: 15px;
    grid-column-gap: 5px;
    max-height: 120px;
    overflow: hidden;
    transition: max-height 1s; */
}
.activityTimeSlotsMobile__expand .activityTimeSlotsMobile__hours {
  max-height: 500px;
}
.activityTimeSlotsMobile__hours > * {
  color: #000;
  margin-bottom: 0;
}
.activityTimeSlotsMobile__error {
  width: 100%;
  padding-top: 20px;
}
.activityTimeSlotsMobile__quantity {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

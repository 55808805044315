.landingPageWrapper {
  width: 63.541666666666664%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.secondRow {
  padding-top: 45px;
}
.secondRow,
.thirdRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.thirdRow > * {
  margin-top: 45px;
}
.thirdRow {
  padding-bottom: 3rem;
}
.fourthRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.infoLinks {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1599px) and (-webkit-min-device-pixel-ratio: 1) {
  .landingPageWrapper {
    width: 85%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .landingPageWrapper {
    width: 80%;
  }
}
@media screen and (min-width: 280px) and (max-width: 1324px) {
  .landingPageWrapper {
    width: 95.327%;
  }
  .landingPageWrapper > article:first-child {
    margin-top: 20px;
    height: 80px !important;
  }
  .secondRow {
    padding-top: 20px;
  }
  .secondRow > article {
    width: 48.5607476635514% ;
    height: 110px !important;
  }
  .thirdRow > * {
    width: 31.411962616822427%;
    height: 110px !important;
    margin-top: 20px;
  }
  .fourthRow > * {
    height: 110px !important;
  }
}
@media screen and (min-width: 700px) and (max-width: 1324px) {
  .landingPageWrapper {
    width: 80%;
  }
  .secondRow > article {
    height: 180px !important;
  }
  .thirdRow > * {
    height: 180px !important;
  }
  .fourthRow > * {
    height: 180px !important;
  }
  .landingPageWrapper > article:first-child {
    height: 120px !important;
  }
}

@media screen and (min-width: 1324px) {
  .fourthRowWrapper > * {
    padding-top: 45px;
  }
}
.Dropdown {
    width: 300px;
}
.Dropdown_action {
    width: 100%;
    height: 60px;
    display: flex;
    position: relative;
    border: 1px solid gray;
}
.Dropdown__selectionHolder {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.Dropdown__indicatorHolder {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid gray;
    cursor: pointer;
}
.Dropdown__icon {
    width: 25px;
    height: 25px;
}
.Dropdown__list {
    display: block;
    position: absolute;
    left: -1px;
    right: -1px;
    top: 100%;
    border: 1px solid gray;
    background-color: white;
    box-shadow: 10px 10px 10px #dcdcdc;
    z-index: 30000;
}
.Dropdown__list > li {
    padding-left: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    border-bottom: 1px solid gray;
    cursor: pointer;
}
.Dropdown__list > li:last-child {
    border: none;
}
.Dropdown__list > li:hover {
    background-color: gray;
    color: #fff;
}

#slider,
#slider ul {
  height: auto;
  /* min-height: 100px; */
  flex-direction: row;
  display: flex;
}

#slider {
  overflow: hidden;
  position: relative;
}

#slider li {
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  color: white;
  /* min-height: 100px; */
  padding: 10px 15px;
}

#slider ul {
  list-style: none;
  position: absolute;
  transition: left 0.2s linear;
  -moz-transition: left 0.2s linear;
  -o-transition: left 0.2s linear;
  -webkit-transition: left 0.2s linear;
  color: #666;
}

.slider-container {
  color: #000;
  margin: 0 auto;
  padding: 0;
  width: 550px;
  min-height: 180px;
  text-align: center;
}

.slider-container p {
  margin: 10px 25px;
  font-weight: semi-bold;
  font-size: 14px;
}

.link {
  color: white;
  text-decoration: underline;
}

.footer {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerRight {
  display: flex;
}
.footerRight > * + * {
  margin-left: 15px;
}
.mobileButton {
  display: none;
}

@media screen and (min-device-width: 280px) and (max-device-width: 650px) {
  .footer {
    justify-content: center;
    padding-top: 25px;
  }
}

.bookingType {
  display: flex;
  align-items: flex-end;
  height: 100%;
  cursor: pointer;
  color: #2d2d2d;
  padding-bottom: 17px;
}

.navItem,
.navItemReached {
  display: flex;
  align-items: center;
  padding-bottom: 7px;
  font-size: 18px;
  font-weight: bold;
}
.navItemText {
  margin-left: 10px;
  white-space: nowrap;
}
.navItemIcon {
  width: 25px;
  height: 25px;
}
.bookingType > span {
  margin-bottom: 8px;
}

.highlight .navItem {
  border-bottom: 2px solid var(--color-primary);
}
.highlight .navItemReached {
  border-bottom: 2px solid var(--color-secondary);
  font-weight: bolder;
}

/* Old css */
.bookingTypeTextWrapper {
  display: flex;
  flex-direction: column;
}
.bookingTypeImage,
.burgerDivClose,
.mobileBookinTypeInfo {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .navItemIcon {
    width: 20px;
    height: 20px;
  }
}
@media screen and (min-width: 280px) and (max-width: 1650px) {
  .navItemText {
    color: var(--color-secondary);
    font-size: 20px;
  }
  .navItem > svg,
  .navItemReached > svg {
    width: 25px;
    height: 25px;
  }
  .bookingType {
    padding-bottom: 0;
  }
  .bookingType {
    align-items: flex-start;
    height: auto;
  }
}

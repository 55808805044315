.bookingContainer {
  width: 100%;
  min-height: 542px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-secondary);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}
.bookingSelection {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.timeWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.timeSelection {
  width: 340px;
  height: 400px;
  padding-right: 11px;
  overflow-y: scroll;
  max-height: 385px;
  display: flex;
  justify-content: space-between;
  flex-basis: content;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 20px;
}
.skipCheck {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 10px;
}
.skipButton {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: left;
}
.skipButton > * {
  margin-top: 10px;
}
.cardsDisplay {
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  align-items: end;
}
/* .cardsDisplay > * + * {
  margin-left: 15px;
} */
.dateNotice {
  width: 95%;
  border-bottom: 1px solid var(--color-primary);
  text-align: center;
  padding-bottom: 6px;
}
.boldTxt {
  font-weight: bold;
  margin-right: 5px;
}
.bookingSection__heading_desktop {
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
}
.bookingSection__heading_mobile {
  padding-top: 15px;
  padding-bottom: 20px;
  display: none;
}
.timeSelection::-webkit-scrollbar,
.cardsDisplay::-webkit-scrollbar {
  width: 5px;
}
.timeSelection::-webkit-scrollbar-track,
.cardsDisplay::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.timeSelection::-webkit-scrollbar-thumb,
.cardsDisplay::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


@media screen and (min-width: 280px) and (max-width: 800px) {
  .bookingSection__heading_desktop {
    display: none;
  }
  .bookingSection__heading_mobile {
    display: block;
  }
  .bookingSection__heading_mobile {
    padding-left: 15px;
  }

  .bookingContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 280px) and (max-width: 1365px) {
  .bookingSelection {
    flex-wrap: wrap;
  }
  .timeWrapper {
    width: 100%;
  }
  .timeSelection {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
  }
  .cardsDisplay {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
  }
  .cardsDisplay__mobile {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    gap: 5px;
    width: 100%;
    margin-bottom: 20px;
  }
  .cardsDisplay > * + * {
    margin-left: 0;
  }
  .timeSelection > * {
    margin-right: 8px;
  }
  .dateNotice {
    border: none;
    width: 100%;
  }
  .skipButton {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (min-width: 650px) and (max-width: 1324px) {
  .bookingSelection, .skipButton {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    justify-content: center;
  }
  .timeSelection,
  .cardsDisplay {
    padding-left: 0;
    padding-right: 0;
  }
  .timeSelection {
    justify-content: center;
  }
  .timeSelection > * {
    margin-right: 15px;
  }
}
.selectBoxWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.selectBox {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
  transition: all 0.5s;
}
.selectBoxWrapperQuantity .selectBox {
  border: 1px solid #707070;
  background-color: #fff;
}
.selectedOption, .placeholder {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  inset: 0;
  z-index: 1;
  padding-left: 10px;
}

.placeholder {
  color: #afafaf;
}

.dropDownArrow {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.dropDownArrow .arrowImageWraper {
  width: 26px;
  height: 26px;
}
.arrowImageWraper > img {
  width: 100%;
  height: 100%;
}

.dropDown {
  height: 1px;
  overflow-y: scroll;
  position: absolute;
  top: 41px;
  left: 0;
  width: 100%;
  z-index: 11;
  background-color: #ededef;
  transition: height 0.5s ease-in-out;
}

.dropDown > li {
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: background-color 0.4s ease-in-out, color 0.1s ease-in-out;
}
.dropDown > li:first-child {
  padding-top: 2rem;
}

.dropDownModeHotel .selectBox {
  background-color: var(--color-primary);
}
.dropDownModeHotel .selectedOption {
  color: white;
}
.dropDownModeHotel .dropDown {
  height: 340px;
  padding-bottom: 20px;
}
.dropDownModeQuantity .selectBox {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropDownModeQuantity .selectedOption {
  color: #000;
}
.dropDownModeQuantity .dropDown {
  height: fit-content;
  padding-bottom: 20px;
  background-color: white;
  top: 39px;
  overflow-y: hidden;
  border: 1px solid #707070;
}
.dropDownModeQuantity .dropDown > li {
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #636363;
}
.currentSelection {
  background-color: var(--color-primary);
  color: #ededef !important;
}
.dropDown > li:hover {
  background-color: var(--color-primary);
  color: white;
}
.nonClickableElement {
  cursor: pointer;
  opacity: 0.8;
  pointer-events: none;
}
.dropDown::-webkit-scrollbar {
  width: 5px;
}
.dropDown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.dropDown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

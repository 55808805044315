.confiramtion_modal {
  width: 100%;
  padding-left: 30px;
  padding-right: 20px;
  padding-bottom: 15px;
}
.checkBoxWrapper {
  display: flex;
  padding-top: 15px;
}
.checkBox {
  width: 32px;
  height: 30px;
  border: 3px solid var(--color-primary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkBoxWrapper > p {
  padding-left: 11px;
}
.groupBookingButtonsValid {
  width: 100%;
  display: flex;
  justify-content: left;
  padding-top: 24px;
  padding-left: 15px;
}
.checkBox > * {
  width: 90%;
  height: 90%;
}
.CreateGrouping__loader {
  width: 100%;
  padding-top: 40px; 
}
.CreateGrouping__loader img {
  width: 45px;
  height: 45px;
}
.CreateGrouping__Title {
  width: 100%;
  display: flex;
  padding-bottom: 2rem;
  padding-left: 30px;
}
.CreateGrouping__Title > * {
  width: 312px;
}
.termsLink {
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-info);
}
@media screen and (min-width: 280px) and (max-width: 900px) { 
  .confiramtion_modal {
    padding-left: 10px;
    padding-right: 10px;
  }
  .CreateGrouping__Title {
    padding-left: 10px;
  }
}
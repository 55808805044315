.activityBooking {
  width: 100%;
  background-color: var(--color-secondary);
  padding-top: 30px;
  padding-bottom: 2.5rem;
}

.checkBoxWrapper {
  display: flex;
  padding-top: 15px;
  align-items: center;
  border-bottom: 1px solid rgb(181, 181, 183);
  padding-bottom: 20px;
  width: 255px;
}
.checkBox {
  width: 30px;
  height: 30px;
  border: 3px solid var(--color-primary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkBoxWrapper > p {
  padding-left: 11px;
}

.closeBTn {
  cursor: pointer;
  align-items: center;
  display: flex;
  padding-left: 20px;
}

.spaBookingsTableWrapper {
  width: 100%;
  height: 75%;
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px;
}

.spaBookingsTableRow {
  display: flex;
}

.spaBookingsTableRow p {
  padding: 10px 10px;
  border-right: 1px solid rgb(181, 181, 183);
}
.spaBookingsTableRow:first-child {
  border-top: 1px solid rgb(181, 181, 183);
}
.spaBookingsTableRow:last-child {
  border-bottom: 1px solid rgb(181, 181, 183);
  border-top: 1px solid rgb(181, 181, 183);
}
.spaBookingsTableRow:not(:first-child, :last-child) {
  border-top: 1px solid rgb(181, 181, 183);
}

.spaBookingsTableGuestNameCell {
  width: 20%;
  border-left: 1px solid rgb(181, 181, 183);
}

.spaBookingsTableTreatmentNameCell {
  width: 30%;
}

.spaBookingsTableDateCell {
  width: 31%;
}

.spaBookingsTablePriceCell {
  width: 6%;
}

.spaBookingsTableButtonsCell {
  width: 15%;
  display: flex;
  padding: 10px 10px;
  border-right: 1px solid rgb(181, 181, 183);
}

.editIcon > * {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

@media (max-width: 350px) {
  .checkBoxWrapper {
    width: 100%;
  }
}

.HorisontalCardsGrid {
    width: 100%;
    height: auto;
    padding-top: 3rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-column-gap: 85px;
    overflow: hidden;
}
@media screen and (min-width: 1325px) and (max-width: 1440px) {
    .HorisontalCardsGrid {
      grid-gap: 0px;
    }
  }
  @media screen and (min-width: 228px) and (max-width: 1324px) {
    .HorisontalCardsGrid {
      justify-content: center;
    }
  }
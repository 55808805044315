.contentWrapper {
  width: 100%;
  /* padding-left: 20px;
  padding-right: 20px; */
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.imgSliderWrapper {
  width: 50%;
  max-width: 670px;
  height: auto;
  max-height: 530px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  cursor: pointer;
}

.entertainmentDetails {
  width: 50%;
  height: 380px;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
  overflow: auto;
  text-align: justify;
  white-space: pre-line;
}

.venueTitle {
  font-size: 20px;
  color: var(--color-primary);
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  margin-bottom: 20px;
  font-weight: bold;
}

.ActDescription {
  font-size: 16px;
  margin-bottom: 20px;
}

.detailsListWrapper {
  padding: 10px;
}

.detailsList {
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 15px;
}

.detailsList li {
  display: flex;
}

.detailsIcon {
  width: 25px;
  height: 25px;
}

.detailsTxt {
  padding-left: 0.5rem;
}

.infoDetailsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f4;
  position: fixed;
  z-index: 100;
  width: 80%;
  max-width: 80%;
  height: auto;
  border: 2px solid var(--color-primary);
  padding: 35px;
}

.infoDetailsBox > .closeButtons {
  width: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.title {
  padding-bottom: 30px;
  padding-right: 20px;
  padding-top: 20px;
  text-align: center;
}

@media screen and (min-width: 900px) and (max-width: 1150px) {
  /* .contentWrapper {
    padding-left: 20px;
    padding-right: 20px;
  } */
  .infoDetailsBox {
    width: 90%;
    max-width: 90%;
  }
}

@media (max-width: 900px) {
  /* .contentWrapper {
    padding-left: 5px;
    padding-right: 5px;
  } */
  .infoDetailsBox {
    width: 97%;
    max-width: 97%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .entertainmentDetails {
    height: 330px;
    padding: 0.7rem;
  }

  .title {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}

@media (min-width: 450px) and (max-width: 600px) {
  .infoDetailsBox {
    height: 80%;
    padding-bottom: 20px;
  }

  .imgSliderWrapper {
    height: 210px;
    width: 90%;
  }

  .entertainmentDetails {
    height: 137px;
    width: 100%;
    padding: 0.7rem;
  }

  .title {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  .title p {
    font-size: 20px !important;
  }

  .detailsList {
    margin-top: 5px;
    height: 5%;
    width: 100%;
  }

  .detailsIcon {
    width: 20px;
    height: 20px;
  }

  .contentWrapper {
    margin-top: 10px;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .infoDetailsBox {
    height: 76%;
    padding-bottom: 20px;
  }

  .imgSliderWrapper {
    height: 200px;
    width: 90%;
  }

  .entertainmentDetails {
    height: 137px;
    width: 100%;
    padding: 0.5rem;
  }

  .title {
    margin-bottom: 15px;
    padding-bottom: 15px;
    padding-right: 0;
  }

  .title p {
    font-size: 20px !important;
  }

  .detailsList {
    margin-top: 5px;
    height: 5%;
    width: 100%;
  }

  .detailsIcon {
    width: 20px;
    height: 20px;
  }

  .contentWrapper {
    margin-top: 15px;
  }
}

@media (min-width: 280px) and (max-width: 350px) {
  .infoDetailsBox {
    height: 75%;
    padding-bottom: 20px;
  }

  .imgSliderWrapper {
    height: 200px;
    width: 90%;
  }

  .entertainmentDetails {
    height: 137px;
    padding: 0.5rem;
    width: 100%;
  }

  .title {
    margin-bottom: 15px;
    padding-bottom: 15px;
    padding-right: 0;
  }

  .title p {
    font-size: 17px !important;
  }

  .detailsList {
    margin-top: 5px;
    height: 5%;
    width: 100%;
  }

  .detailsIcon {
    width: 20px;
    height: 20px;
  }

  .contentWrapper {
    margin-top: 15px;
  }
}

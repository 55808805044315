.cardWrapper {
  width: 100%;
  background: #fff;
  margin-top: 15px !important;
  border: 1px solid black;
}

.rightSummaryContainer > p:first-child {
    padding-top: 0 !important;
}

.rightSummaryContainer {
  padding-left: 20px;
  padding-right: 20px;
}

.editIcon > * {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.iconFillInPast > svg {
  fill: #949494 !important;
}
.information_landing_page,
.information {
  display: flex;
  flex-direction: column;
}
.information {
  align-items: center;
  justify-content: center;
  align-content: space-around;
}
.informationWrapperMobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.informationWrapperDesktop {
  height: 100%;
  display: flex;
  padding-bottom: 20px;
  padding-right: 12px;
  padding-left: 25px;
}
.line {
  width: 100%;
  height: 15px;
}
.information_landing_page {
  justify-content: flex-end;
}
.information_landing_page > .information_icon_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}
.information_icon {
  width: 55px;
  height: 55px;
}
.information_msg {
  color: white;
  font-size: 1em;
}
.information_landing_page {
  padding: 0;
  padding-left: 45px;
  padding-bottom: 40px;
}
.fullyColoredClass {
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}
.fullyColoredClass > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 280px) and (max-width: 1324px) {
  .errorMsgWrapper {
    padding: 10px;
  }
  .errorMsg {
    margin-left: 20px;
  }
}

.day_use_activities_selections_btn_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto auto 20px auto;
}

.day_use_no_avail_wrapper {
    background: #F2F4F5;
    width: 95%;
    left: 0;
    right: 0;
    margin: auto auto 20px auto;
    padding: 20px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.day_use_check_another_hotel_wrapper {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.day_use_avail_section_icon {
    width: 25px;
    height: 25px;
}
.groupBookingFormWrapper__group_table {
  width: 100%;
  padding-bottom: 2rem;
}
.groupBookingFormWrapper__group_table_body_wrapper {
  width: 100%;
}
.groupBookingFormWrapper__group_table_header, .groupBookingFormWrapper__group_table_body {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}
.groupBookingFormWrapper__group_table_header {
  background-color:var(--color-primary);
  padding-left: 28px;
  padding-right: 389px;
}
.groupBookingFormWrapper__group_table_body {
  background-color: transparent;
  padding-right: 27.62784090909091%;
  padding-left: 28px;
}
.groupBookingFormWrapper__group_table_header > label {
  font-size: 20px;
  color: #fff;
  width: 33%;
  text-align: center;
}
.groupBookingFormWrapper__group_table_body > h3 {
  font-weight: bolder;
  font-size: 18px;
  width: 33%;
  text-align: center;
}
.groupBookingFormWrapper__group_table_body > h3:first-child {
  padding-left: 21px;
}
.groupBookingFormWrapper__group_table_body > h3:first-child , .groupBookingFormWrapper__group_table_header  > label:first-child {
  text-align: left;
}
.groupBookingFormWrapper__group_table_delete {
  cursor: pointer;
}
.groupBookingFormWrapper__group_table_body_wrapper > div:nth-child(2) {
  padding-top: 5px;
}
.groupBookingFormWrapper__groupCreatorRow {
  padding-bottom: 5px;
}
.groupBookingFormWrapper__groupCreatorRow > h3 {
  color: #847d86;
  font-size: 18px;
}
.groupBookingFormWrapper__horisontalLine {
  height: 1px;
  background-color: #969db8;
  margin-left: 15px;
  width: 83.80681818181817%;
}
.groupBookingFormWrapper__info {
  display: flex;
  padding-left: 50px;
  padding-top: 20px;
}
.groupBookingFormWrapper__info > *:nth-child(2) {
  padding-left: 25px;
}
.groupBookingFormWrapper__group_table_delete > svg{
  width: 30px;
  height: 30px;
}
@media screen and (min-width: 280px) and (max-width: 1150px) {
  .groupBookingFormWrapper__group_table_body {
    padding-right: 10px;
    padding-left: 28px;

  }
  .groupBookingFormWrapper__group_table_header {
    padding-right: 0;
    padding-left: 28px;
  }
  .groupBookingFormWrapper__group_table_header > label {
    width: 100%;
  }
  .groupBookingFormWrapper__group_table_body > h3:first-child {
    padding-left: 0;
  }
  .groupBookingFormWrapper__horisontalLine {
    width: 100%;
    margin-left: 0;
  }
  .groupBookingFormWrapper__info {
    padding-left: 10px;
  }
  .groupBookingFormWrapper__info h3 {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
}
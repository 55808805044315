.popUpWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grayOutScreen {
  background-color: #ccc;
  opacity: 0.5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.InfoBox,
.alertBox {
  /* display: flex; */
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  position: fixed;
  z-index: 100;
  width: 95%;
  height: 95%;
  min-height: 250px;
  border: 2px solid var(--color-primary);
  padding: 20px;
}
.InfoBox > div {
  padding: 10px;
}
.alertBox > .alertBoxButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: center;
  padding-bottom: 20px;
}
.infoDetailsBox {
  margin-bottom: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f2f2f4;
  position: fixed;
  z-index: 100;
  width: 80%;
  max-width: 80%;
  height: auto;
  min-height: 62%;
  border: 2px solid #525e8b;
  border-radius: 5px;
  padding: 20px;
}
.infoDetailsBox > .closeButtons {
  width: 3%;
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 30px;
  right: 30px;
}

.balanceDue {
  margin-left: 72%;
}

.markdown {
  font-size: 10px !important;
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .InfoBox,
  .alertBox {
    width: 95%;
  }
}

@media screen and (min-width: 610px) and (max-width: 755px) {
  .balanceDue {
    margin-left: 65%;
  }
}

@media screen and (min-width: 483px) and (max-width: 610px) {
  .balanceDue {
    margin-left: 55%;
  }
}

@media screen and (min-width: 280px) and (max-width: 483px) {
  .balanceDue {
    margin-left: 35%;
  }
}
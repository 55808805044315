.helpPage {
    width: 100%;
}
.helpPage__headline {
    width: 100%;
}
.helpPage__title {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpPage__desc, .helpPage__video_section {
    padding-left: 310px;
}
.helpPage__desc {
    padding-bottom: 20px;
}
.helpPage__video_section {
    width: 100%;
    padding-right: 310px;
}
.helpPage__video_title__box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #F7F7F8;
    border-radius: 5px;
    padding: 40px 75px 40px 75px;
    margin-bottom: 54px;
}
.helpPage__video_title_desc {
    width: 50%;
    padding-right: 40px;
}
.helpPage__video_title_desc > div {
    text-align: justify;
}
.helpPage__media {
    width: 590px;
    height: 285px;
}
.helpPage__media > img {
    object-fit: contain;
}
.helpPage__highlitedTitle {
    width: 100%;
    height: 65px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.helpPage__highlitedTitle > p{
    color: #fff;
}
.helpPage__questions_terms {
    width: 100%;
    padding-left: 310px;
    padding-right: 310px;
    padding-top: 63px;
    padding-bottom: 30px;
}
.helpPage__questions {
    width: 100%;
    border-radius: 5px;
    background-color: #F7F7F8;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 45px;
    padding-bottom: 45px;
}
.helpPage__terms {
    width: 100%;
    padding-top: 80px;
}
.helpPage__terms > * + *{
    padding-top: 25px;
}
.helpPage__questions > * + * {
    margin-top: 15px;
}
.helpPage__questions > *:last-child {
    margin-top: 45px;
}
.HelpPage__goBack {
    padding-top: 1rem;
}
.CategoryWrapper {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    margin-top: 60px;
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
@media screen and (min-width: 280px) and (max-width: 1224px) {
    .helpPage__desc, .helpPage__videoBtn {
        padding-left: 10px;
    }
    .helpPage__desc {
        text-align: center;
        padding-right: 10px;
    }
    .helpPage__questions_terms, .helpPage__video_section {
        padding-left: 10px;
        padding-right: 10px;
    }
    .helpPage__video_title__box {
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .helpPage__video_title__box > * + * {
        margin-top: 20px;
    }
    .helpPage__video_title_desc {
        padding-top: 0;
        padding-right: 0;
        width: 590px;
        text-align: center;
    }
    .helpPage__video_title_desc > div:last-child {
        text-align: justify;
    }
}
@media screen and (min-width: 280px) and (max-width: 750px) { 
    .helpPage__video_title_desc {
        width: 85%;
    }
    .helpPage__media {
        width: 85%;
    }
    .helpPage__questions {
        padding-left: 10px;
        padding-right: 10px;
    }
    .helpPage__terms {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }
    .helpPage__questions_terms {
        padding-top: 0px;
    }
    .helpPage__video_title__box {
        margin-bottom: 0px;
    }
    .helpPage__questions_terms, .helpPage__video_section {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media screen and (min-width: 1224px) and (max-width: 1600px) {
    .helpPage__desc, .helpPage__videoBtn {
        padding-left: 150px;
    }
    .helpPage__questions_terms, .helpPage__video_section {
        padding-left: 150px;
        padding-right: 150px;
    }
}
.GrayWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-secondary);
}
.BookingSection {
  padding-left: 30px;
  padding-right: 30px;
}
.WhatsOnGuideDesktop {
  width: fit-content;
  max-height: 781px;
  padding: 20px 20px 20px 15px;
}

.WhatsOnGuideMobile,
.WhatsOnGuideDesktop {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.WhatsOnGuideTVVertical,
.WhatsOnGuideTVHorizontal {
  overflow: hidden;
  scroll-behavior: smooth;
}

.WhatsOnGuideTVVertical {
  max-height: 90vh;
}

.WhatsOnGuideTVHorizontal {
  flex-direction: row;
  background-color: #fff;
}
.WhatsOnGuideTVHorizontal > * + * {
  margin-left: 25px;
}

.WhatsOnGuideDesktop::-webkit-scrollbar {
  width: 9px;
}

.WhatsOnGuideDesktop::-webkit-scrollbar-track {
  background: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.WhatsOnGuideDesktop::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 3px;
}

.WhatsOnGuideDesktop::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

.WhatsOnGuideTV {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 700px);
  grid-auto-rows: 950px;
  grid-gap: 25px;
}
@media screen and (min-width: 280px) and (max-width: 800px) {
  .BookingSection {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-height:1050px) and (max-height: 1300px) {
  .WhatsOnGuideTVVertical {
    max-height: 65vh;
  }
}

@media screen and (min-height:1300px) and (max-height: 1550px) {
  .WhatsOnGuideTVVertical {
    max-height: 71vh;
  }
}

@media screen and (min-height:1550px) and (max-height: 1850px) {
  .WhatsOnGuideTVVertical {
    max-height: 75vh;
  }
}

@media screen and (min-height:1850px) and (max-height: 2300px) {
  .WhatsOnGuideTVVertical {
    max-height: 79vh;
  }
}

@media screen and (min-height:2300px) and (max-height: 2800px) {
  .WhatsOnGuideTVVertical {
    max-height: 83vh;
  }
}

@media screen and (min-height:2801px) and (max-height: 3799px) {
  .WhatsOnGuideTVVertical {
    max-height: 86vh;
  }
}
@media screen and (min-height:3800px) and (max-height: 3899px) {
  .WhatsOnGuideTVVertical {
    max-height: 88vh;
  }
}
@media screen and (min-height:3900px) and (max-height: 5000px) {
  .WhatsOnGuideTVVertical {
    max-height: 90vh;
  }
}

.activityCardMobile {
  background-color: var(--color-secondary);
  overflow: hidden;
  min-height: 390px;
  position: relative;
  cursor: pointer;
}
.activityCardMobile__img {
  width: 100%;
  height: 125px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  padding-bottom: 9px;
}
.activityCardMobile__txt {
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 5px;
  overflow-y: auto;
  height: 250px
}
.txt__price {
  margin-top: 10px;
  margin-bottom: 15px;
  color: var(--color-primary);
}
.activityCardMobile__img > * {
  margin-left: 10px;
}
.Kiosk {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex: 1;
  flex-direction: column;
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
}

.Login__btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
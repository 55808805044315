.verticalLine {
  background-color: #fff;
  width: 2px;
}

@media only screen and (max-width: 600px) {
  .verticalLine {
    display: none;
  }
}

.groupBookingWrapper {
  width: 67.70833333333334%;
  height: auto;
  padding-bottom: 4rem;
}
.manageGroup,
.manageGroup .groupBookingInformation {
  width: 1011px;
}
.createGroup,
.createGroup .groupBookingInformation {
  width: 1408px;
}
.viewTitle {
  width: 100%;
  text-align: center;
}
.groupBookingInformation > div {
  padding-bottom: 40px;
}
.groupBookingFormWrapper {
  width: 100%;
  background-color: var(--color-secondary);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.groupBookingForm {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 30px;
  padding-bottom: 37px;
  padding-right: 350px;
}
.createGroupInputWrapper {
  display: flex;
  flex-wrap: wrap;
}
.createGroupInputWrapper > article {
  width: 312px;
}
.createGroupInputWrapper > article:nth-child(2) {
  margin-left: 93px;
}
.msgWrapper {
  padding-top: 25px;
  width: 100%;
}
.msgWrapper > div {
  justify-content: flex-start;
}
.groupBookingButtonsWrapper {
  margin-left: 30px;
  width: 77%;
}
.groupBookingButtonsWrapper button:nth-child(2) {
  margin-left: 3rem;
}
.groupDisplay {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.groupFormed > .createGroupInputWrapper,
.groupFormed > button {
  display: none;
}
.previewTable_item_groupLeader {
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (min-width: 1000px) and (max-width: 1599px) {
  .groupBookingWrapper {
    width: 95%;
    padding-bottom: 4rem;
  }
  .groupBookingInformation {
    width: 100% !important;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .groupBookingWrapper,
  .groupBookingInformation {
    width: 80%;
  }
  .groupBookingInformation {
    width: 100% !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1350px) {
  .groupBookingForm {
    padding-right: 100px;
  }
}

@media screen and (min-height: 1000px) and (max-height: 1250px) {
  .groupBookingWrapper {
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 280px) and (max-width: 1150px) {
  .groupBookingWrapper,
  .manageGroup,
  .createGroup {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 1.5rem;
  }
  .createGroup .groupBookingInformation,
  .manageGroup .groupBookingInformation {
    width: 100%;
  }
  .groupBookingFormWrapper {
    width: 100%;
    padding-bottom: 0;
  }
  .viewTitle {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
  }
  .groupBookingInformation {
    padding-left: 10px;
    padding-right: 10px;
  }
  .groupBookingInformation > div {
    padding-bottom: 20px;
  }
  .groupBookingForm {
    width: 100%;
    padding-right: 10px;
  }
  .groupBookingForm > button {
    margin-top: 26px;
  }
  .groupBookingButtonsWrapper {
    background-color: #fff;
    width: 100%;
    margin-left: 0;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 280px) and (max-width: 900px) {
  .groupBookingForm {
    width: 100%;
    padding-right: 0;
    padding-left: 10px;
    justify-content: space-between;
  }
  .createGroupInputWrapper > article:nth-child(2) {
    margin-left: 15px;
  }
  .groupBookingButtonsWrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 280px) and (max-width: 646px) {
  .createGroupInputWrapper > article:nth-child(2) {
    margin-left: 0px;
    margin-top: 25px;
  }
}

.activitiesWrapper {
  width: 78.125%;
  height: auto;
}
.activitiesHeadline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.information {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
}
.promotions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promotionCards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 3.5rem;
}
.promotionCards > * {
  margin-top: 2rem;
}
.acitivityFooter {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.information > * {
  white-space: pre-line;
}
@media screen and (min-width: 1000px) and (max-width: 1599px) {
  .activitiesWrapper {
    width: 75%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .activitiesWrapper {
    width: 70%;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1874px) {
  .activitiesWrapper {
    width: 70%;
  }
}
@media screen and (min-width: 1325px) and (max-width: 1440px) {
  .activitiesWrapper {
    width: 75%;
  }
}

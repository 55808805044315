.headLandingPage,
.headBookingPages {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.headLandingPage > .blueBg,
.headBookingPages > .blueBg {
  width: 100%;
  height: 50px;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.headLandingPage .warnerLogo {
  width: 150px;
  height: 60%;
}
.headLandingPage > button {
  margin-top: -25px;
}
.headBookingPages .warnerLogo {
  display: none;
}
.headLandingPage > .blueBg > button {
  display: none;
}
.headBookingPages > button {
  display: none;
}
.hotelImage {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 105px;
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .hotelImage,
  .headLandingPage {
    display: none;
  }
}

.LogoAndTitle__imageWrapper {
    width: 100%;
    height: 55px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
}
.LogoAndTitle__cancel {
    cursor: pointer;
}
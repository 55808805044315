.PXPForm {
    width: 95%;
    height: 100%;
    border: 1px solid var(--color-primary);
  }
  
  .PXPForm__header,
  .PXPForm__form {
    padding-left: 15px;
    padding-right: 15px;
  }
  .PXPForm__header,
  .PXPForm__form,
  .PXPForm__input_label,
  .PXPForm__input,
  .PXPForm__submit {
    width: 100%;
  }
  .PXPForm__header,
  .PXPForm__submit {
    background-color: var(--color-primary);
  }
  .PXPForm__title,
  .PXPForm__submit {
    color: #fff;
    font-size: 20px;
  }
  
  .PXPForm__header {
    height: 45px;
    display: flex;
    align-items: center;
  }
  .PXPForm__form {
    height: calc(100% - 45px);
    background-color: #f2f2f4;
    padding-top: 25px;
  }
  .PXPForm__form > * + * {
    margin-top: 20px;
  }
  .expiry {
    display: flex;
    justify-content: space-between;
  }
  .PXPForm__input_label_expiry {
    width: 48%;
  }
  .PXPForm__label {
    font-weight: bold;
  }
  .PXPForm__input {
    margin-top: 5px;
    padding: 10px;
  }
  .PXPForm__submit {
    height: 50px;
    font-size: 20px;
    cursor: pointer;
  }
  
.PXPResult {
  width: 90%;
  height: 100%;
  margin-top: 20px;
  text-align: center;
  color: white;
  background-color: #5b75a0;
  padding: 50px;
  border-radius: 5px;
}

.CategoryBanner {
  width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  align-items: flex-end;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.CategoryBanner__arrow {
  margin-right: 25px;
  transition: all 0.4s ease-in-out;
}
.CategoryBanner__arrow_up {
  transform: rotate(180deg);
}
@media screen and (min-width: 280px) and (max-width: 700px) {
  .CategoryBanner {
    padding-left: 10px;
    padding-right: 10px;
  }
  .CategoryBanner__arrow {
    margin-right: 15px;
  }
}
.welcomeModalWrapper {
  width: 63.5417%;
  padding-bottom: 40px;
  padding-top: 55px;
  position: relative;
}

.usernameWrapper {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.usernameWrapper > h5 {
  padding-right: 10px;
}
.link {
  text-decoration: underline;
  color: var(--color-primary);
  margin-left: 10px;
  cursor: pointer;
}
.infoWrapper {
  padding-bottom: 20px;
}
.welcomingModalInfo {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.WelcomeModal__highlights {
  padding: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 2px;
  font-weight: bold;
  font-size: 1.25rem;
}
.whats_on {
  position: absolute;
  right: 0;
  top: 15px;
  font-size: 1.2rem;
}
.logOut {
  padding-left: 10px;
  font-size: 12px;
}
@media screen and (min-device-width: 1000px) and (max-device-width: 1599px) and (-webkit-min-device-pixel-ratio: 1) {
  .welcomeModalWrapper {
    width: 85%;
  }
}

@media screen and (min-device-width: 1600px) and (max-device-width: 1874px) and (-webkit-min-device-pixel-ratio: 1) {
  .welcomeModalWrapper {
    width: 80%;
  }
}
@media screen and (min-width: 280px) and (max-width: 700px) {
  .welcomeModalWrapper {
    width: 95.327%;
    padding-bottom: 15px;
    padding-top: 20px;
  }
}
@media screen and (min-width: 700px) and (max-width: 1324px) {
  .welcomeModalWrapper {
    width: 80%;
    padding-bottom: 15px;
    padding-top: 20px;
  }
}

.Sorting {
    /* width: 44%; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 2rem;
    padding-bottom: 20px;
    margin-top: 7px;
}
@media screen and (min-width: 285px) and (max-width: 1324px) {
    .Sorting {
      justify-content: center;
      align-items: center;
    }
  }
  
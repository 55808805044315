.activityCard {
  border-bottom: none !important;
}
.activityIcons {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
}
.activityIcons > * + * {
  margin-left: 15px;
}
.activityDesc {
  padding-left: 0.5rem;
}
.activityDesc > p:first-child {
  color: var(--color-primary);
}
.desc {
  line-height: 21px;
}

.entertainmentCard {
  display: flex;
  position: relative;
}

/* .entertaintmentImage {
  width: 50%;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1.5rem 2.5rem;
  text-align: center;
  cursor: pointer;
} */

.selectionBox {
  margin-bottom: 20px;
}

.unselect {
  position: relative;
  z-index: 104;
}

.highlight:after {
  content: "";
  display: block;
  position: absolute;
  height: 101%;
  width: 101%;
  top: -1px;
  left: -1px;
  background-color: var(--color-primary);
  opacity: 0.4;
  z-index: 103;
}

.disable:after {
  content: "";
  display: block;
  position: absolute;
  height: 100.1%;
  width: 100.2%;
  top: 0px;
  left: -1px;
  background-color: #ccc;
  opacity: 0.5;
}

.entertainmentDetails {
  width: 50%;
  height: 260px;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0.5rem 0.8rem;
  background-color: white;
}

.title {
  font-size: 20px;
  color: var(--color-primary);
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  font-weight: bold;
}

.time {
  font-size: 16px;
  color: var(--color-primary);
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
  text-align: left;
  align-items: center;
  height: 35px;
  max-height: 40px;
}

.time h1 {
  font-weight: bold;
}

.banner_check_icon {
  width: 1.5rem;
  margin-left: 10px;
}

.information_icon {
  margin-left: 6px;
  width: 1.9rem;
}

.entertainmentDetailsWrapper {
  text-align: left;
  cursor: pointer;
  color: var(--color-info);
  text-decoration: underline;
  position: absolute;
  /* z-index: 104; */
  top: 222px;
  font-size: 16px !important;
}

.entertainmentImgSliderWrapper {
  width: 50%;
  height: 260px;
}

.banner {
  position: absolute;
  z-index: 105;
  background: var(--color-primary);
  width: 50%;
  height: 13%;
  left: -1.2px;
}

.bannerText {
  text-align: center;
  height: 100%;
  display: grid;
  align-items: center;
  width: 100%;
  position: absolute;
  color: var(--color-secondary);
  z-index: 106;
  font-size: 19px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 15px;
}

.banner p {
  font-size: 16px;
}

.entertainmentTitle {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 47px;
  max-height: 47px;
}

.entertainmentDescription {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 110px;
  font-size: 16px !important;
}

.emptySlotCard {
  height: 260px;
  margin-bottom: 20px;
  background-color: white;
}

.emptySlotCardTopLine {
  height: 15px;
  max-height: 15px;
  background-color: #CECECE;
}

.emptySlotCardText {
  font-size: 24px;
  color: #CECECE;
  width: 35%;
}

.emptySlotCardIcon {
  margin-top: 5px;
  width: 1.5rem;
}

@media (min-width: 800px) and (max-width: 1324px) {
  .entertainmentImgSliderWrapper {
    height: 260.5px;
  }
}

@media (min-width: 1000px) and (max-width: 1112px) {
  .entertainmentDetails {
    padding: 0.3rem;
  }
  .time {
    font-size: 15px;
  }
  .information_icon {
    width: 1.7rem;
    margin-left: 5px;
  }
  .banner_check_icon {
    width: 1.4rem;
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .entertainmentDetails {
    padding: 0.3rem;
  }
  .title {
    font-size: 18px;
  }
  .entertainmentTitle {
    height: 44px;
    max-height: 44px;
  }
  .time {
    font-size: 13px;
  }
  .information_icon {
    width: 1.5rem;
    margin-left: 5px;
  }
  .bannerText {
    font-size: 15px !important;
  }
  .banner_check_icon {
    width: 1.2rem;
  }
}

/* @media (min-width: 800px) and (max-width: 900px) {
  .entertainmentDetails {
    padding: 0.3rem;
  }
  .title {
    font-size: 18px;
  }
  .entertainmentTitle {
    height: 54px;
  }
  .time {
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .information_icon {
    width: 2rem;
    margin-left: 5px;
  }
  .bannerText {
    font-size: 14px;
    padding: 0 10px;
  }
  .banner_check_icon {
    width: 1.2rem;
  }
} */

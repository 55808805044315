.activitesMobile {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  flex: 1;
  align-items: center;
}
.activitesMobile__cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: 390px;
  justify-content: center;
  grid-column-gap: 8px;
  grid-row-gap: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.SpaVisuals {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 700px) and (max-width: 1324px) {
  .activitesMobile > p,
  .activitesMobile > div:nth-child(2) {
    text-align: center;
  }
}
@media screen and (min-width: 280px) and (max-width: 425px) {
  .activitesMobile__cards {
    grid-template-columns: repeat(auto-fill, 260px);
    grid-auto-rows: 320px;
    grid-row-gap: 25px;
  }
}

.Details {
  width: 100%;
  background-color: var(--color-secondary);
  padding-bottom: 3rem;
}
.Details__Header {
  width: 100%;
  display: flex;
  position: relative;
}
.Details__Header_leftAligned {
  text-align: left;
  padding-left: 3.5rem;
}
.Details__Header_centered {
  text-align: center;
}
.Details__Title {
  width: 100%;
  padding-top: 1rem;
  display: flex;
  align-items: center;
}
.Details__closeBTn {
  width: 60px;
  height: 45px;
  position: absolute;
  cursor: pointer;
  right: 7px;
  top: 10px;
}
.Details__activityOverview {
  width: 100%;
  display: flex;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 1rem;
}
.Details__activityImages {
  width: 40%;
  display: flex;
  align-items: flex-start;
}
.Details__sliderWrapper {
  background-size: cover;
  border-radius: 5px;
}
.Details__activityDescription {
  width: 60%;
  padding-left: 20px;
  /* min-height: 540px; */
  padding-top: 0.5rem;
}
.Details__activityDescription > p:first-child {
  color: var(--color-primary);
}
.Details__activitiesTextWrapper {
  width: 100%;
  /* padding-top: 1rem; */
  white-space: pre-line;
}
.Details__activitiesTextWrapper > * {
  line-height: 30px;
}
.Details__activitiesTextWrapper > * > * + * {
  margin-top: 1.5rem;
}
.Details__activitiesIconWrapper {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.Details__activitiesIconWrapper > * {
  margin-left: 2.5rem;
}
.Details__PrecedeBtn {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 30px;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.checkBoxWrapper {
  display: flex;
  padding-top: 15px;
  align-items: center;
  border-bottom: 1px solid rgb(181, 181, 183);
  padding-bottom: 20px;
  width: auto;
  padding-top: 15px;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  flex-direction: column;
}
.checkBox {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: 3px solid var(--color-primary);
  cursor: pointer;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.checkBoxWrapper > p {
  padding-left: 11px;
}
.termsAndConditions {
  color: var(--color-info);
}
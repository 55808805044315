.SpaPresentation {
  width: 100%;
  height: fit-content;
  max-height: 400px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  border-radius: 10px;
}
.SpaPresentation__image {
  object-fit: contain;
  border-radius: 10px;
}
.loginWrapper {
  width: 100%;
  min-height: calc(100vh - 55px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loginWrapper > div:first-child {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.formWrapper {
  width: 780px;
  max-width: 95vw;
  height: fit-content;
  margin: auto;
  position: relative;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  padding: 1rem 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.headline,
.inputSection,
.btnWrapper {
  margin-top: 0;
}
.headline {
  width: 100%;
}
.loginTitleWrapper {
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
}
.inputSection {
  width: 100%;
  margin-top: 25px;
}
.inputSection > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.selectBoxWrapper {
  justify-content: center;
}
.inputWrapper {
  justify-content: space-between;
}
.inputWrapper > article {
  width: 100%;
}
.inputWrapper > *:last-child {
  padding-top: 15px;
}
.selectBoxWrapper > article {
  width: 100%;
}
.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.btnWrapper > button {
  padding-left: 25px;
  padding-right: 25px;
}
.helpWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.helpWrapper > * + * {
  margin-left: 15px;
}
.helpLink {
  color: var(--color-primary);
  text-decoration: underline;
  font-weight: bold;
}
.errorInfoWrapper {
  width: 100%;
  height: auto;
}
.errorInfoWrapper p {
  margin-left: 30px;
}
.inputSection div:nth-child(3) img {
  width: 45px;
  height: 45px;
}
.loginTitleWrapper,
.inputWrapper,
.errorInfoWrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}
.DesktopDescription {
  width: 100vw;
}
.LoginForm__AlertImg {
  width: 100%;
}
.loginFormLogo {
  width: 100%;
  max-width: 335px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

@media screen and (max-width: 1023px) {
  .inputWrapper > article,
  .selectBoxWrapper > article {
    width: 100%;
  }
  .inputWrapper {
    justify-content: center;
  }
  .helpWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
  .helpWrapper > a {
    font-size: 14px;
  }
  .errorInfoWrapper {
    width: 100%;
  }
  .inputSection > div {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 820px) {
  .formWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.DropDownBox__Top {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 25px;
}
.DropDownBox__Top_selectedItem {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DropDownBox_icon {
  width: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  right: 0px;
}
.DropDownBox_icon_rotate {
  transform: rotateZ(180deg);
}
.DropDownBox__label {
  font-weight: bold;
}
.DropDownBox__list li:last-child {
  border-bottom: none;
}

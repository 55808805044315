.timeSlots {
  width: 50%;
  padding-top: 0.5rem;
}

.timeSlotTitle {
  color: var(--color-primary);
}
.timeWrapper {
  width: 100%;
  padding-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 100px);
  grid-row-gap: 1rem;
  grid-column-gap: 2.4rem;
}

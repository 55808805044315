.information_icon {
  width: 40px;
  height: 2.5rem;
  margin-left: 15px;
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
}

.day_use_basket_card_top_wrapper {
    background: #F2F4F5;
    width: 100%;
    left: 0;
    right: 0;
    margin-bottom: 16px;
}

.day_use_basket_card_details {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: white;
    display: flex;
    height: 223px;
}

.day_use_basket_card_image {
    height: 100%;
    width: 40%;
}

.day_use_basket_card_summary {
    padding: 10px;
    width: 60%;
    display: flex;
    background-color: #F2F2F4;
}

.day_use_basket_card_title {
    font: normal normal bold 24px/27px Crimson Pro;
    color: #3C3C3C;
}

.day_use_basket_card_sub_title {
    font: normal normal 300 16px/22px Open Sans;
    text-align: left;
    color: #000000;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
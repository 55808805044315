.Filter {
  padding-top: 15px;
  display: flex;
}
.Filter > * + * {
  margin-left: 20px;
}
@media screen and (min-width: 228px) and (max-width: 1001px) {
  .Filter {
    padding-top: 25px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
  }
  .Filter > * + * {
    margin-left: 0px;
    margin-top: 20px;
  }
}

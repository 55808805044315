.selectionAllignment {
  flex: 1;
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.allign {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 15px;
}

.infoWrapper {
  width: 100%;
  background-color: var(--color-secondary);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.selectionWrapper {
  width: 100%;
  background-color: var(--color-secondary);
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.selectionWrapper:after {
  content: "";
  position: absolute;
  top: 250px;
  bottom: 220px;
  left: 50%;
  border-left: 1px solid #b5b5b7;
  transform: translate(-50%);
}

.date {
  width: 100%;
  text-align: center;
  padding-bottom: 0.3rem;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rulesText {
  width: 100%;
  text-align: center;
  padding-bottom: 0.3rem;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}


.venueDetailsWrapper {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-bottom: 20px;
}

.titleMobile {
  text-align: center;
  width: 98%;
}
.titleMobile p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.descMobile {
  padding: 1rem;
}

.descMobile p {
  font-size: 16px !important;
}

.expandBtn {
  display: flex;
  justify-content: right;
  padding: 0.75rem;
  width: 98%;
}

.venueImageWrapper {
  width: 98%;
  height: 150px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.venueImageSelWrapper:after {
  content: "";
  width: 100%;
  height: 150px;
  display: block;
  background-color: var(--color-primary);
  opacity: 0.6;
  z-index: 103;
}

.expandedVenueTitle {
  position: absolute;
  z-index: 104;
  text-align: center;
  vertical-align: middle;
  height: 150px;
  line-height: 150px;
  width: 100%;
  color: white;
  font-weight: bold;
  font-size: 24px !important;
}

.skipButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  padding-right: 2rem;
  padding-left: 2rem;
}

.skipButton > * {
  margin-top: 10px;
}

.checkBoxWrapper {
  display: flex;
  padding-top: 15px;
  align-items: center;
  border-bottom: 1px solid rgb(181, 181, 183);
  padding-bottom: 20px;
  width: auto;
}
.checkBox {
  width: 30px;
  height: 30px;
  border: 3px solid var(--color-primary);
  cursor: pointer;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.checkBoxWrapper > p {
  padding-left: 11px;
}

@media screen and (min-width: 800px) and (max-width: 1324px) {
  /* .selectionWrapper {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  } */

  .selectionAllignment {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .selectionWrapper {
    /* padding-left: 0.5rem;
    padding-right: 0.5rem; */
    height: 100%;
  }
  .selectionWrapper:after {
    content: none;
  }
}

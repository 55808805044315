.CardConfirmation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.CardConfirmation__items {
    width: 85%;
    background-color: #e8e8e8;
    padding-left: 15px;
    padding-bottom: 20px;
}
.CardConfirmation__confirmation_text {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}
.CardConfirmation__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
}
.CardConfirmation__button {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.CardConfirmation__item > * {
    width: fit-content;
    text-align: left;
}

.CardConfirmation__item > * + * {
    width: fit-content;
    margin-top: 10px;
}
.giftCardsWrapper {
  width: 80%;
  margin: 55px 0;
  min-height: calc(100vh - 220px);
}

.Headline {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.giftCardContainer, .giftCardSingleContainer {
  display: grid;
  margin-top: 55px;
  width: 100%;
}

.giftCardContainer {
  grid-template-columns: 1fr;
  gap: 40px;
}

.giftCard, .giftCardSingle {
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
}

.giftCardSingle {
  display: flex;
  flex-flow: row wrap;
}

.giftCardContent, .giftCardSingleContent {
  flex: 60%;
  display: flex;
  flex-direction: column;
  padding-block: 40px;
}

.giftCardImage, .giftCardSingleImage {
  flex: 50%;
  min-height: 200px;
  background-position: center center;
  background-size: cover;
}

.giftCardImage {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (min-width: 992px)  {
  .giftCardContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .giftCardSingle {
    flex-flow: row-reverse wrap;
  }

  .giftCardSingleImage {
    min-height: 400px;
  }

  .giftCardSingleContent {
    flex: 50%;
    display: flex;
    padding: 0 40px;
    justify-content: center;
  }
}

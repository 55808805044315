.LoginInput {
  display: flex;
  align-items: flex-end;
  position: relative;
}
.LoginInput__icon {
  width: 20px;
  height: 100%;
  margin-right: 10px;
}
.LoginInput__action {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.LoginInput__action > input {
  width: 100%;
  height: 64px;
  color: #3c3c3c;
  font-size: 18px;
  padding: 25px 15px;
}
.LoginInputLabel {
  color: #3c3c3c;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}
.LoginInput__action * {
  border-radius: 0 !important;
  outline: none !important;
}
.LoginInput__action_border * {
  border: none !important;
}
.LoginInput__action *::placeholder {
  opacity: 0.7;
}
/* @media screen and (min-width: 280px) and (max-width: 400px) {
  .LoginInputLabel {
    line-break: loose;
  }
  .LoginInputLabel {
    display: block;
  }
} */
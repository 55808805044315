.activityPreviewMobile {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}


.activityPreviewMobile__details_selections {
  padding-left: 15px;
  padding-right: 15px;
}
.activityPreviewMobile__selections > p {
  color: var(--color-primary);
}
.activityPreviewMobile__timeSlotsContatiner {
  width: 100%;
}
.activityPreviewMobile__timeSlotsContatiner > * + * {
  margin-top: 20px;
}
.bookingButtons {
  padding-top: 20px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.bookingButtons > button:last-child {
  margin-left: 25px;
}
.activitySelectDropDownWrapper {
  width: 350px;
}

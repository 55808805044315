.TreatsFormWrapper {
    width: 100%;
    background: var(--color-secondary);
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 15px;
}

.TreatsFormSectionWrapper {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    border-radius: 5px;
}
.itinerary {
  flex: 1 1;
  padding: 15px;
}

.itineraryContainer {
  width: 100%;
  padding-left: 170px;
  padding-right: 170px;
}
.itineraryHeadline {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hotelDetailsWrapper {
  display: flex;
  width: 100%;
  background-color: #f2f2f4;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 170px;
  padding-right: 170px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.itineraryDescription {
  white-space: pre-line;
}
.reservationAndLocationDetails {
  padding-top: 2rem;
  width: 45%;
}
.hotelDetailsList > * {
  margin-top: 1.5rem;
}
.mapWrapper {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
}
.map {
  width: 450px;
  height: 230px;
  border: 2px solid #9aa199;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 0.5rem;
}
.itinerarayInformation {
  padding-top: 2rem;
}
.servicesWrapper {
  width: 100%;
}
.servicesWrapper__info {
  padding-top: 20px;
}
.userServices > * {
  margin-top: 1.5rem;
}
.userService {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.userService > * + * {
  margin-left: 1.5rem;
}
.bookingItineraryWrapper {
  width: 100%;
  background-color: #f2f2f4;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.offerCardsWrapper {
  width: 100%;
  padding-left: 165px;
  padding-right: 165px;
}
.offerTitle {
  width: 100%;
  padding-top: 0.7rem;
  text-align: center;
  padding-bottom: 2rem;
}
.allOffers {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 470px);
  grid-gap: 2.813em;
  justify-content: center;
}
.bottomInformationText {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}
.bottomInformationText > * {
  margin-top: 15px;
}
.desktopContactItem > button {
  margin-left: 25px;
}
.extendYourStay {
  padding-top: 1rem;
}
.printInfo {
  display: none;
}
.addressLink {
  color: var(--color-primary);
  text-decoration: underline;
}

.contentWrapper {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  background-color: var(--color-secondary);
}

/* .contentWrapper :first-child, :nth-child(2) {
  margin-top: 0;
} */

@media screen and (min-width: 1324px) and (max-width: 1575px) {
  .desktopContactItem > button {
    margin-left: 0px;
    margin-top: 25px;
  }
}
@media screen and (min-width: 700px) and (max-width: 1155px) {
  .mapWrapper,
  .reservationAndLocationDetails {
    width: 100%;
  }
  .mapWrapper {
    margin-top: 3em;
  }
}

@media screen and (min-width: 280px) and (max-width: 450px) {
  .itineraryDescription {
    text-align: left !important;
  }
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .itineraryContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .itineraryHeadline {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    align-items: flex-start;
  }
  .hotelDetailsWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .reservationAndLocationDetails {
    padding-top: 0;
    width: 100%;
  }

  .mobileContactItem {
    line-height: 15px;
  }
  .address__mobile,
  .contact_mobile {
    padding-top: 10px;
  }
  .contact_mobile {
    padding-bottom: 15px;
  }
  .itinerarayInformation {
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .servicesWrapper {
    /* background-color: #f2f2f4; */
    padding-left: 0;
    padding-right: 0;
  }
  .userService {
    align-items: flex-start;
  }
  .userService__mobile {
    flex-direction: column;
    justify-content: start;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
  }
  .userServices > div:last-child {
    background-color: white;
    padding-top: 25px;
  }
  .bookingItineraryWrapper {
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .bottomInformationText {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .itineraryHeadline > p:first-child {
    margin-left: 10px;
  }
  .hotelDetailsList > * {
    margin-top: 15px;
  }
  .servicesWrapper__info,
  .itinerarayInformation,
  .itineraryDescription,
  .userServices > * {
    padding-left: 10px;
    padding-right: 10px;
  }

  .userService > * + * {
    margin-left: 0;
    margin-top: 15px;
  }
  .mapWrapper,
  .reservationAndLocationDetails {
    width: 100%;
  }
  .mapWrapper {
    align-items: flex-start;
    min-width: 250px;
  }
  .mapWrapper > a {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .map {
    width: 100%;
  }
  .mapWrapper {
    margin-top: 3em;
  }
  .itineraryHeadline > p:nth-child(2) {
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 700px) and (max-width: 1324px) {
  .itineraryHeadline > p:first-child {
    width: 100%;
    text-align: center;
  }
  .itinerary {
    padding-left: 25px;
    padding-right: 25px;
  }
  .map {
    height: 300px;
  }
}

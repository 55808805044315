.fadeElement {
  opacity: 0.4;
  pointer-events: none;
  width: 160px;
}

.disableElement {
  opacity: 0.4;
  pointer-events: none;
}

.daysContainer {
  width: 100%;
  height: 95px;
  /* padding-top: 10px; */
  display: flex;
  justify-content: space-between;
}
.daysWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.daysWrapper > a {
  font-size: 12px;
  color: #78a0bc;
  text-decoration: underline;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
  width: 119px;
}
.mobileBookingRouteInfo {
  display: none;
}
.arrows {
  height: 100%;
  width: 25px;
  display: flex;
  justify-content: center;
  background-color: rgb(126, 123, 126);
  cursor: pointer;
}
.btnWrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}
.dayList_mobile {
  width: 100%;
  height: 75px;
  position: relative;
  /* padding-top: 20px; */
  padding-left: 50px;
  padding-right: 50px;
}
.dayList_mobile__slider_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.slider_container_show {
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: 0.4s ease-in-out;
}
.arrow {
  position: absolute;
  cursor: pointer;
  top: 37px;
  z-index: 3;
}
.arrow > svg {
  fill: var(--color-primary) !important;
}
.dayList_mobile__leftArrow {
  left: 15px;
}
.dayList_mobile__rightArrow {
  right: 15px;
}
@media screen and (min-width: 280px) and (max-width: 800px) {
  .daysContainer {
    height: 75px;
  }
}

.ManageBooking__headline {
  width: 100%;
  padding-left: 55px;
}
.ManageBooking__previewTable {
  width: 100%;
}
.previewTable_header {
  width: 100%;
  height: 50px;
  padding-left: 57px;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
}
.previewTable_header > h3 {
  font-size: 20px;
  color: #fff;
  width: 50%;
}
.previewTable_item {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 72px;
  padding-left: 57px;
}
.previewTable_item > h3 {
  width: 50%;
}
.previewTable_item > h3:nth-child(2) {
  padding-left: 90px;
}
.total {
  width: 100%;
  padding-left: 560px;
  padding-top: 27px;
  display: flex;
}
.totalNumber {
  padding-left: 20px;
}
.ManageBooking__buttons {
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
}
.helpWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
}
.helpWrapper > span {
  color: #000;
  margin-left: 20px;
}
.helpLink {
  color:  #000;
  text-decoration: underline;
}
.ManageBooking__delete {
  width: 50px;
  height: 100%;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 280px) and (max-width: 1150px) {
  .ManageBooking__headline {
    padding-left: 20px;
  }
  .ManageBooking__buttons {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
  }
  .previewTable_header {
    padding-left: 20px;
  }
  .previewTable_item {
    padding-left: 20px;
  }
  .previewTable_item > h3:nth-child(2)  {
    padding-left: 0;
  }
  .total {
    padding-left: 35%;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 280px) and (max-width: 446px) {
  .ManageBooking__buttons {
    justify-content: center;
  }
  .ManageBooking__buttons > * + * {
    margin-top: 25px;
  }
}
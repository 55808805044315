.DetailsBoxDesktop {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 5;
  margin-bottom: 20px;
}
.DetailsBoxMobile {
  position: fixed;
  background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
  top: 70px;
  border: 2px solid var(--color-primary);
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 20px;
  z-index: 2222;
  overflow: auto;
}
@media screen and (min-width: 280px) and (max-width: 700px) {
  .DetailsBoxMobile {
    bottom: 0;
  }
}
@media screen and (min-width: 700px) and (max-width: 1324px) {
  .DetailsBoxMobile {
    /* height: fit-content; */
    box-shadow: 5px 5px 5px #dcdcdc;
  }
}
